import {
  addClass,
  allAnimationsFinished,
  removeClass,
  removeNode,
  requestAnimationFrame,
} from '@acng/frontend-bounty';
import {createPromise} from '@acng/frontend-bounty/std/control.js';

import {STYLE_ENTER, STYLE_LEAVE} from '../style/flags.js';

/**
 * @param {Element} element
 */
export const enter = (element) => animate(element, STYLE_ENTER);

/**
 * @param {Element} element
 * @returns {Promise<void>}
 */
export const leave = async (element) => {
  await animate(element, STYLE_LEAVE);

  removeNode(element);
};

/**
 * @param {Element} element
 * @param {string} name
 * @returns {Promise<void>}
 */
export const animate = (element, name) =>
  createPromise(
    /**
     * The `setTimout` is required for transitions to start if the
     * element has just been inserted.
     *
     * @param {(value: void) => void} resolve
     */
    (resolve) => {
      addClass(element, name);

      requestAnimationFrame(async () => {
        const ACTIVE = `${name}-active`;

        addClass(element, ACTIVE);
        await allAnimationsFinished(element, true);

        removeClass(element, ACTIVE, name);
        resolve();
      });
    }
  );
