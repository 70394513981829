/**
 * Stargazer Callback Helper
 *
 * @see [Stargazer](https://docs.devac.de/stargazer/)
 *
 * @module
 * @author Jacob Viertel <jv@onscreen.net>
 */

import {forEach, map} from '@acng/frontend-bounty/std/array.js';

/**
 * @param {Element} element
 * @param {Array<import('@acng/frontend-stargazer/types').ConnectedCallback>} callbacks
 * @returns {() => () => void}
 */
export const ConnectEach = (element, callbacks) => () =>
  DisconnectEach(
    element,
    map(callbacks, (callback) => {
      DEBUG: if (element.hasAttribute('debug')) console.debug('-<>-', {element, callback});
      return callback(element);
    })
  );

/**
 * @param {Element} element
 * @param {Array<import('@acng/frontend-stargazer/types').DisconnectedCallback | void>} callbacks
 * @returns {() => void}
 */
export const DisconnectEach = (element, callbacks) => () =>
  forEach(callbacks, (callback) => {
    if (callback) {
      DEBUG: if (element.hasAttribute('debug')) console.debug('>  <', {element, callback});
      callback(element);
    }
  });
