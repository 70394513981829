import {create, createMany, path, validate, expr, alias, incomplete, index, middleware, on} from '@acng/frontend-voyager';
import {replaceUrl} from '@acng/frontend-relativity/history';
import {CTX_PROVIDE} from '@acng/frontend-relativity/minify';

import {hookFeature} from 'acng/core/config/feature';
import {rootRoute} from 'acng/core/config/routes';
import {userRoute, guestRoute} from 'acng/userPool/config/routes';
import {mainView, stickyView} from 'acng/layout/service/sequence.js';

import {ctxAmateur} from '../context/amateur.js';
import {userVisitsAmateur} from 'acng/amateurPool/service/profile-visit.js';
import {amateurPoolFeature} from './feature';

/** @typedef {import('acng/userPool/factory/user').User} User */

export const amateurRoute = rootRoute.create(
  path('/sedcard/{nickname}'),
  stickyView(amateurPoolFeature, 'sedcard-header'),
  incomplete,
  alias('sedcardNav'),
  createMany(
    [
      index,
      incomplete,
      mainView(amateurPoolFeature, 'sedcard'),
      alias('sedcard'), //
      on('active', () => {
        if (!rootRoute.globals.amateur) {
          console.log('ACTIVE SEDCARD amateur not found!!', rootRoute.globals.nickname);
          replaceUrl('/#/');
        }
      }),
    ],
  )
);

// only used by laforge
export const sedcardIndex = amateurRoute.create(index);

userRoute.createMany(
  [
    incomplete,
    path('/amateurs'),
    createMany(
      [
        incomplete,
        expr(/(?:online|top|new|gameScore)$/),
        stickyView(amateurPoolFeature, 'list-header'),
      ],
      [
        path('/{amateurs}'),
        validate(({amateurs}) => /^(online|top|new|gameScore)$/.test(/** @type {string} */ (amateurs))),
        mainView(amateurPoolFeature, 'list'),
      ],
      [
        path('/livedating'),
        mainView(amateurPoolFeature, 'list-livedating'),
      ]
    )
  ],
  [path('/visits'), alias('profileVisits')],
  [path('/amateurs/search'), mainView(hookFeature, 'amateurSearch'), alias('amateurSearch')],
  [
    incomplete,
    path('/feed'),
    stickyView(amateurPoolFeature, 'feed-header'),
    create(
      path('/{feed}'),
      validate(({feed}) => /^(all|posts|media|favorites)$/.test(/** @type {string} */ (feed))),
      mainView(amateurPoolFeature, 'feed'),
    )
  ]
);

guestRoute.create(
  path('/amateursignup'), //
  alias('amateursignup')
);

ctxAmateur[CTX_PROVIDE](null, null);

/**
 * @typedef RootScope
 * @property {boolean} floatingMenu -
 */
runSedcardNav.$inject = ['user', 'Amateur'];
/**
 * @param {User} user -
 * @param {import('acng/amateurPool/factory/Amateur').AmateurConstructor} Amateur -
 */
export function runSedcardNav(user, Amateur) {
  amateurRoute.config(
    on('change', event => {
      const amateur = /** @type {CustomEvent} */ (event).detail.route.params.amateur ?? null;
      DEBUG: console.info('produce', amateur);
      ctxAmateur[CTX_PROVIDE](null, amateur);
    }),
    on('enter', ev => {
      const amateur = /** @type {import('acng/amateurPool/factory/Amateur').Amateur | undefined} */ (amateurRoute.params.amateur);
      if (user.guest || !amateur) return;
      DEBUG: amateurRoute.VERBOSE && console.debug('AmateurVisit triggered by Route-Event %O', ev);
      userVisitsAmateur(amateur.id);
    }),
    on('nochange', () => user.trackActivity()),
    middleware((next, prev) => {
      if (next.nickname == prev.nickname) {
        next.amateur = prev.amateur;
        return true;
      }

      const nextNickname = /** @type {string} */ (next.nickname);
      DEBUG: amateurRoute.VERBOSE && console.debug('amateurPool/config/routes runSedcardNav', {nextNickname});
      return Amateur.getByNickname(nextNickname)
        .then((amateur) => next.amateur = amateur)
        .catch(reason => {
          console.warn(`sedcardNav Amateur '${nextNickname}' rejected with reason`, reason);
        });
    })
  );
}
