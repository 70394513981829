/**
 * @todo
 * - Clean up legacy styles
 *
 * @see [Legacy Countdown Styles](../../../css/core/countdown.css)
 */

/**
 * @group CSS Class
 */
export const NOVA_TEASER = 'nova-teaser';

/**
 * @group CSS Custom Property
 */
export const __TEASER_BORDER_RADIUS = '--teaser-border-radius';
