import {debug} from '@acng/frontend-bounty/dom/debug.js';
import {IMG} from '@acng/frontend-bounty/dom/element.js';
import {defineRegistryElement, defineRenderElement, task} from '@acng/frontend-stargazer';

defineRegistryElement(IMG, (name) => {
  /**
   * @param {HTMLImageElement} image
   * @param {import('@acng/frontend-stargazer').InternalEngine} engine
   * @returns {Promise<void>}
   */
  const decode = async (image, engine) => {
    try {
      console.log('decode', {image, src: image.src});
      await image.decode();
      DEBUG: if (debug(image)) console.log('image decoded', {image});
    } catch (reason) {
      ASSERT: console.warn('image not decoded', {image, reason, engine});
    }
  };

  defineRenderElement(name, (image, engine) => {
    DEBUG: if (debug(image)) console.debug('add decode task', {image, engine});
    //task(engine, decode(image, engine));
    task(engine, new Promise((resolve, reject) => {
      image.addEventListener('load', resolve, {once: true});
      image.addEventListener('error', reject, {once: true});

    }));
  });
});
