import {once} from "@acng/frontend-bounty/dom/event.js";
import {createPromise} from "@acng/frontend-bounty/std/control.js";
import {defineRenderElement, task} from "@acng/frontend-stargazer";

defineRenderElement('video', (video, engine) => {
  if (video.autoplay) {
    console.info('<video>', {currentSrc: video.currentSrc, ready: video.readyState, network: video.networkState, video});
    task(engine, createPromise((resolve) => 
      once(video, 'canplaythrough', () => {

    console.info('<video> canplaythrough', {currentSrc: video.currentSrc, ready: video.readyState, network: video.networkState, video});
        resolve(null);
      })
    ));
  }
});
