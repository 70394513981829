import {assign} from '@acng/frontend-bounty/object.js';
import {FULLY_ASSIGNED} from '@acng/frontend-bounty/typeguard.js';
import {post} from 'acng/core/service/backend';

/**
 * @implements {ChallengeData}
 */
export class Challenge {
  /**
   * @param {ChallengeData} data
   */
  constructor(data) {
    assign(this, data);
    ASSERT: {
      this.id = data.id;
      this.name = data.name;
      this.value = data.value;
      this.key = data.key;
      FULLY_ASSIGNED(this, data);
    }
  }

  /**
   * @param {Element} element
   * @returns {Promise<boolean>}
   */
  async achieve(element) {
    try {
      await post('achievements/user-achievement', this, element);

      return true;
    } catch (reason) {
      return false;
    }
  }
}

/**
 * @private
 * @typedef {import("../service/http").AchievementData & {
 *   key?: string | null;
 * }} ChallengeData
 */
