import {createContext} from "@acng/frontend-relativity";

/**
 * @typedef {import("acng/amateurPool/factory/Amateur").Amateur} Amateur
 */

/**
 * Observe an {@link Amateur} of the closest providing DOM parent.
 * Provide an {@link Amateur} to a DOM subtree.
 *
 * @type {import("@acng/frontend-relativity").LegacyContext<?Amateur> & import("@acng/frontend-relativity").ObservableType<?Amateur>}
 */
export const ctxAmateur = createContext();
