// @ts-nocheck
import angular from 'angular';
import {LivecamSession} from '../model/session.js';
import {shuffle} from 'acng/core/factory/helper.js';
import {ctxAmateur} from 'acng/amateurPool/context/amateur.js';
import {CTX_PROVIDE, CTX_VALUE} from '@acng/frontend-relativity/minify';
import {documentElement} from '@acng/frontend-bounty';
import {data} from '../test/list.js';
import {ctxCamEvent} from '../context/cam-event.js';
/* prettier-ignore */
angular
  .module('livecam')

  .constant('moveCam', {
  // '992305': '8832104', // Lara27 => MicaelaSchaefer
    15973366: '8832104' // Mica-Live => MicaelaSchaefer
  // '9215838': '9146372' // TeddySteen => Muschichi
  })

  .factory(
    'Livecam', ['http', 'User', '$log', 'AmateurFilter', 'jsLoader', 'payment', '$location', 'Amateur', '$q', 'HttpCache', 'moveCam',
      (
        http,
        User,
        $log,
        AmateurFilter,
        jsLoader,
        payment,
        $location,
        Amateur,
        $q,
        HttpCache,
        moveCam) =>
      {
        const cams = new Map();
        const onlineList = [];
        const runningList = [];
        const loadingList = [];
        const cache = new HttpCache();
        cache.errorExpire = 10000;
        cache.minExpire = 5000;

        function generateLists() {
          loadingList.length = 0;
          runningList.length = 0;
          onlineList.length = 0;
          cams.forEach(cam => {
            if (cam.running && !cam.loading) runningList.push(cam);else
              if (cam.loading) loadingList.push(cam);else
                if (cam.is_online) onlineList.push(cam.id);
          });
        }

        function update() {
          return cache.get(
            '_list',
            () =>
              http().get(`api/livecam/${User.tester ? 'devlist' : 'list'}`, {
                dontIntercept: true
              }),
            res => {
              cams.forEach(cam => {
                cam.is_online = false;
                cam.is_occupied = false;
              });
              //res.data = data;
              res.data.forEach(cam => {
                // MICA SPECIAL
                if (moveCam[cam.id]) {
                  cam.startCamWithId = cam.id;
                  cam.id = moveCam[cam.id];
                }
                // MICA SPECIAL
                cam.is_occupied = !cam.is_online;
                if (cams.has(cam.id)) {
                  Object.assign(cams.get(cam.id), cam);
                } else {
                  cams.set(cam.id, new Livecam(cam));
                }
              });
              generateLists();
              ctxCamEvent[CTX_PROVIDE](null, null);
            },
            null
          );
        }

        class Livecam {
          id;
          prices = [];
          show_types = [];
          is_online = false;
          toycontrol = false;
          is_occupied = false;
          running = false;
          loading = false;
          motto = null;

          constructor(data) {
            this.id = data.id;
            if (data.startCamWithId) {
              this.startCamWithId = data.startCamWithId;
            }
            this.prices = data.prices || [];
            this.show_types = data.show_types || [];
            this.is_online = data.is_online;
            this.toycontrol = data.toycontrol;
            this.motto = data.motto;
          }

          isAvailable(showType) {
            return (
              this.is_online &&
          this.show_types.includes(showType) &&
          !!this.prices[showType]);

          }

          getPrice(showType) {
            return this.prices[showType];
          }

          async start(type) {
            if ($location.path().indexOf('livecamShow') == -1) {
              LivecamSession.prevPath = $location.path();
            }
            if (Livecam.loading) {
              throw 'livecams are busy';
            }
            this.startLoading();
            let amateur;
            try {
              amateur = await Amateur.get(this.id);
              const session = await http().post(
                '/api/livecam/start-session',
                $.param({
                  id: this.startCamWithId || this.id,
                  type,
                  nickname: amateur.getNickname()
                }),
                { dontIntercept: true }
              );
              await jsLoader(session.data.clients.js);
              let data = session.data.session;
              console.log('xx', session);
              const ls = new LivecamSession(
                this,
                data.id,
                data.duration,
                amateur,
                data.show_type,
                session.data.clients.vx ? 'vx' : 'lcr',
                session.data.clients.host,
              );


              $log.info('session start', session.data, ls);
              this.showType = data.show_type;
              $location.url('livecamShow/'.concat(data.id));
            } catch (err) {
              this.stopLoading();
              switch (err.status) {
                case 401:
                  User.guestSignup('livecam.signupRequired', {
                    nickname: amateur.nickname
                  }) || User.clear();
                  throw null;
                case 402:
                  if (err.data && err.data.code === 4001) {
                    throw err;
                  }
                  if (!User.level && type == 'free') {
                    payment.overlay('livecam.freeshow.limitReached', null, [
                      'free-cam']
                    );
                  } else {
                    payment.overlay('livecam.paymentRequired', {
                      nickname: amateur.nickname
                    });
                  }
                  throw null;
                default:
              }
              throw err;
            };
          }

          startLoading() {
            this.loading = true;
            generateLists();
          }

          stopLoading() {
            this.loading = false;
            generateLists();
          }

          startRunning() {
            this.running = true;
            if (this.loading) {
              this.stopLoading();
            }
            generateLists();
          }

          stopRunning() {
            this.running = false;
            generateLists();
          }

          /**
           * @param {string} id
           */
          static isOnline(id) {
            return cams.has(id) && cams.get(id).is_online;
          }

          static freeMessagesAllowed(id) {
            return (
              cams.has(id.toString()) &&
          cams.get(id.toString()).running &&
          cams.get(id.toString()).showType != 'voyeur');

          }

          static get(id) {
            if (!id) {
              return null;
            }
            update();
            return cams.get(id.toString());
          }

          static getLoadingList() {
            return update().then(() => loadingList);
          }

          static list() {
            return update().then(() => onlineList);
          }

          static otm() {
            return cache.get(
              '_otm',
              () => http(true).get('/api/livecam/otm', { dontIntercept: true }),
              res => res.data
            );
          }

          /**
           * @param {number} [amateurId]
           */
          static isActive(amateurId) {
            if (amateurId) {
              return loadingList.find(cam => cam.id == amateurId) || runningList.find(cam => cam.id == amateurId);
            }
            return !!(loadingList.length || runningList.length);
          }
        }

        Object.assign(AmateurFilter.get('livecam'), {
          category: 'livecam',
          strainer: Livecam.list
        });

        Object.assign(AmateurFilter.get('livecam_shuffle'), {
          category: 'livecam',
          strainer: Livecam.list,
          transform: (list) => {
            const amateur = ctxAmateur[CTX_VALUE](documentElement);
            if (amateur) {
              const i = list.indexOf(amateur.id);
              if (i >= 0) {
                list.splice(i, 1);
              }
            }
            //shuffle(list); // should be shuffled, but online first gets lost
          },
        });

        const sf = AmateurFilter.get('search');
        sf.strainer = () =>
          sf.category == 'livecam' ? Livecam.list() : $q.resolve([]);

        Amateur.prototype.getLivecamShows = function () {
          const livecam = Livecam.get(this.id);
          return livecam ? livecam.show_types : [];
        };

        Amateur.prototype.getShowIcon = function (type) {
          return {
            normal: Livecam.get(this.id)?.toycontrol ? 'lovense' : 'cam',
            voyeur: 'voyeur',
            exclusive: 'cam'
          }[type];
        };

        Amateur.prototype.livecamIsOccupied = function () {
          return Livecam.get(this.id)?.is_occupied;
        };

        Amateur.prototype.livecamIsOnline = function () {
          const livecam = Livecam.get(this.id);
          return !!livecam && (livecam.is_online || livecam.is_occupied);
        };

        Amateur.prototype.hasToycontrol = function () {
          return !!Livecam.get(this.id)?.toycontrol;
        };

        return Livecam;
      }]
  )
  .directive('onsdLivecamsNew', ['PimpMyRide', 'http', (PimpMyRide, http) =>
    PimpMyRide('LivecamsNew', false, () =>
      http(true)
        .get('/api/livecam/new')
        .then(res => res.data.map(cam => cam.id))
    )]
  )
  .directive('onsdLivecamsToycontrol', ['PimpMyRide', 'http', (PimpMyRide, http) =>
    PimpMyRide('LivecamsToycontrol', false, () =>
      http(true)
        .get('/api/livecam/toycontrol')
        .then(res => res.data.map(cam => cam.id))
    )]
  );
