import {CLASS, TITLE, get} from '@acng/frontend-bounty/dom/attribute.js';
import {debug} from '@acng/frontend-bounty/dom/debug.js';
import {query} from '@acng/frontend-bounty/dom/query.js';
import {IS, typeguard} from '@acng/frontend-bounty/typeguard.js';
import {ObserveAttributes} from '@acng/frontend-bounty/dom/observe.js';
import {forOf} from '@acng/frontend-bounty/object.js';
import {hasClass} from '@acng/frontend-bounty';

import {defineRegistryAttribute, defineRenderAttribute} from '@acng/frontend-stargazer';

import {allScenes, fromAttribute, observe} from '../service/sequence.js';
import {STYLE_BUSY, STYLE_OPEN} from '../service/style.js';

/**
 * @group DOM Attribute
 */
export const DATA_SCENE_TITLE = 'data-scene-title';

defineRegistryAttribute(DATA_SCENE_TITLE, (name) => {
  defineRenderAttribute(name, (element, value) => {
    const target = value ? query(element, value) : element;
    ASSERT: typeguard(`[${name}] target`, target, IS(HTMLElement));

    const observeScene = ObserveAttributes([TITLE, CLASS], (template) => {
      const title = get(template, TITLE);
      DEBUG: if (debug(element)) {
        if (hasClass(template, STYLE_OPEN)) {
          console.info('page opened or title changed', {element, template, title});
        } else {
          console.debug('closed page or title changed', {element, template, title});
        }
      }
      if (hasClass(template, STYLE_OPEN) && !hasClass(template, STYLE_BUSY)) {
        target.textContent = title ?? get(element, TITLE);
      }
    });

    return () => {
      const sequence = fromAttribute(element);
      const off = observe(sequence, (newScenes) => forOf(newScenes, observeScene));

      allScenes(sequence, (scene) => {
        observeScene(scene);

        if (hasClass(element, STYLE_OPEN)) {
          target.textContent = get(element, TITLE) ?? '?';
        }
      });

      return () => {
        observeScene(null);
        off();
      };
    };
  });
});
