// bounty
import {typeguard} from '@acng/frontend-bounty/typeguard.js';
// enterprise
import {cdn} from 'acng/core/service/backend.js';
import {OTM_LIST} from './typeguard.js';
import {fsk} from 'acng/userPool/fsk.js';

export const AOTM_TYPE = 'amateur-profile';
export const LOTM_TYPE = 'livecam';
export const OTM_TYPE_LIST = /** @type {const} */ ([AOTM_TYPE, LOTM_TYPE]);

/**
 * @param {Element} element
 * @param {OTM_TYPE_LIST[number]} type
 * @returns {Promise<import("../types").Otm[]>}
 */
export const getList = async (element, type) => {
  const list = await cdn(`${type}/otm`, element);
  ASSERT: typeguard(`${element.localName}, api response otmList`, list, OTM_LIST);

  return list.filter((otm) => otm.fsk <= fsk.get());
};

/**
 * @param {import('../types').Otm[]} list
 * @param {(amateurId: string) => boolean} priorityFn
 * @returns {void}
 */
export const sort = (list, priorityFn) => {
  for (let count = 0, i = list.length - 1; i >= count; i--) {
    if (priorityFn(list[i].amateurId)) {
      const swap = list[i];
      list.splice(i, 1);
      list.unshift(swap);
      count++;
      i++;
    }
  }
};
