/**
 * @module Open Overlay
 * @since 3.77.0
 * @author Jacob Viertel <jv@onscreen.net>
 * @author Markus Kirscht <markus.kirscht@iventuregroup.com>
 *
 * It is possible to open an `Overlay` directly from the templates.
 *
 * `Overlay` parameters are currently **not** supported.
 *
 * ```html
 * <span :open-overlay="payment">Payment Overlay</span>
 * ```
 *
 * @see [Overlay](../../../core/service/overlay.ts)
 */

import {on, onClick} from '@acng/frontend-bounty';

import {defineRenderAttribute} from '@acng/frontend-stargazer';
import {inject} from 'acng/core/service/ng.js';
import {swapClass} from '../../service/style.js';
import {STYLE_ACTIVE, STYLE_INACTIVE} from '../style.js';

const MODULE = 'layout/config/controller/open-overlay';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Verbose import', MODULE);

defineRenderAttribute(':open-overlay', (element, overlayName) => {
  DEBUG: if (VERBOSE || element.hasAttribute('debug')) console.debug(MODULE, {element, overlayName});

  const Overlay = inject('onsOverlay');

  swapClass(element, STYLE_ACTIVE, Overlay.isOpen(overlayName));

  onClick(element, () => {
    DEBUG: if (VERBOSE || element.hasAttribute('debug')) {
      console.info(MODULE, 'click', {element, overlayName});
    }

    swapClass(element, STYLE_ACTIVE);
    const overlay = Overlay.create(overlayName).disableBackdrop().open(element);
    on(overlay, 'close', () => swapClass(element, STYLE_INACTIVE), {once: true});
  });
});
