import {Widget} from '@acng/frontend-stargazer';
import {
  addClass,
  append,
  createElement,
  createSpan,
  createTemplate,
  document,
  remove,
  setText,
  sizeOfArray,
} from '@acng/frontend-bounty';
import {ctxCommentable, ctxCreatedComment, listComments} from '../model/commentable';
import {createPerceptiveHTML, createStream} from '@acng/frontend-discovery';

import {commentFeature} from '../config/feature.js';
import {CTX_OBSERVE} from '@acng/frontend-relativity/minify';

const tagName = 'onsw-comment-list';
const limit = 20;
const VERBOSE_DEBUG = false;

commentFeature.defineWidget(
  tagName,
  class extends Widget {
    static template = false;
    static consumables = [ctxCommentable, ctxCreatedComment];
    /** @type {import('../model/commentable').Commentable | null} */
    #commentable = null;
    /** @type {HTMLParagraphElement | undefined} */
    #empty;

    #stream = createStream([], async (offset) => {
      if (!this.#commentable) {
        throw Error('something went wrong');
      }
      DEBUG: console.warn(`${tagName} pull`, {offset});
      const comments = await listComments(this.#commentable, limit, offset);
      const count = sizeOfArray(comments);
      DEBUG: console.warn(`${tagName} pulled`, {comments});
      if (!offset && !count) {
        (async () => {
          const empty = createElement('p');
          addClass(empty, 'empty');
          this.#empty = empty;
          setText(empty, await commentFeature.translate('noComment'));
          append(this, empty);
        })();
      } else if (this.#empty) {
        remove(this.#empty);
        this.#empty = undefined;
      }
      return comments;
    });

    #perceptive = createPerceptiveHTML(this, this.#stream, async (comment, loader) => {
      DEBUG: console.info(tagName, {comment, loader});
      const header = createElement('header');
      const nickname = createSpan('name');
      const text = createElement('p');
      setText(nickname, comment.nickname);
      append(
        header,
        nickname,
        ` ${await commentFeature.translate('date')} `,
        comment.created_at.toLocaleString(document.body.lang, {dateStyle: 'medium', timeStyle: 'short'})
      );
      text.innerHTML = comment.text;
      return createTemplate('', header, text);
    });

    // TODO use relativity/watch, perceptive will handle stream changes in future
    connectedCallback() {
      DEBUG: if (VERBOSE_DEBUG) this.#perceptive.enableDebug('comments');
      ctxCommentable[CTX_OBSERVE](this, async (commentable) => {
        if (this.#commentable?.type == commentable?.type && this.#commentable?.id == commentable?.id) {
          return;
        }
        DEBUG: console.debug(`${tagName} connected with commentable`, {commentable});
        this.#commentable = commentable;
        this.#stream.streamReset([]);
        this.#perceptive.connect();
      });

      ctxCreatedComment[CTX_OBSERVE](this, async (created) => {
        if (
          created &&
          created.commentable.type == this.#commentable?.type &&
          created.commentable.id == this.#commentable?.id
        ) {
          DEBUG: console.debug(`${tagName} observed comment creation`, created);
          this.#stream.streamReset([]);
          this.#perceptive.connect();
        }
      });
    }

    disconnectedCallback() {
      DEBUG: console.debug(`${tagName} disconnected`);
      this.#perceptive.disconnect();
    }
  }
);
