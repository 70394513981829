/**
 * @group DOM Attribute
 * TODO collect bounty
 */
export const TYPE = 'type';

/**
 * @group DOM Attribute
 */
export const TIMING_START = 'timing-start';

/**
 * @group DOM Attribute
 */
export const TIMING_END = 'timing-end';
