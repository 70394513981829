// bounty
import {now} from '@acng/frontend-bounty/timing/now.js';
import {parse} from '@acng/frontend-bounty/std/date.js';
import {get, set} from '@acng/frontend-bounty/dom/attribute.js';
import {append, removeNode} from '@acng/frontend-bounty';
import {isConnected, isHTMLElement} from '@acng/frontend-bounty/dom/type.js';
import {debug} from '@acng/frontend-bounty/dom/debug.js';
import {TEMPLATE} from '@acng/frontend-bounty/dom/element.js';
// stargazer
import {
  DATA_TRANSCLUDE,
  content,
  defineCompileAttribute,
  defineRegistryAttribute,
  defineRenderAttribute,
} from '@acng/frontend-stargazer';
// nova
import {getSequence} from '../service/sequence';
import {TIMING_END, TIMING_START} from './common.js';

/**
 * @group DOM Attribute
 *
 * Set the *sequence* to be used by
 *
 * - [Slideshow](../element/slideshow.js)
 * - [Pagination](../element/pagination.js)
 * - [Scene Title](./scene-title.js)
 *
 * for the element **and** the subtree.
 *
 * @example
 * ```html
 * <section seqence-name="slideshow1">
 *   <h1 data-scene-title></h1>
 *   <nova-slideshow></nova-slideshow>
 * </section>
 * ```
 *
 * @see [Sequence](../service/sequence.js)
 */
export const SEQUENCE_NAME = 'sequence-name';

defineRegistryAttribute(SEQUENCE_NAME, (attributeName) => {
  const MINIMUM_DURATION = 1000;

  defineCompileAttribute(attributeName, (element) => {
    if (isHTMLElement(element, TEMPLATE)) {
      DEBUG: if (debug(element)) console.log('set [data-transclude]', {element});

      set(element, DATA_TRANSCLUDE);
    }
  });

  defineRenderAttribute(attributeName, (element, sequenceName) => {
    if (isHTMLElement(element, TEMPLATE)) {
      const start = parse(get(element, TIMING_START)) ?? -Infinity;
      const end = parse(get(element, TIMING_END)) ?? Infinity;
      const time = now();
      const timeUntilStart = start - time;
      const timeUntilEnd = end - time;
      const show = () => isConnected(element) && append(content(getSequence(sequenceName)), element);
      const hide = () => removeNode(element);
      DEBUG: if (debug(element)) console.debug('template is intended to be added to sequence', {element});

      if (timeUntilEnd < MINIMUM_DURATION) {
        DEBUG: if (debug(element)) console.debug('the remaining time is too short', {element});
        return;
      }

      return () => {
        if (timeUntilStart > 0) {
          setTimeout(show, timeUntilStart);
        } else {
          show();
        }

        setTimeout(hide, timeUntilEnd);
      };
    }
  });
});
