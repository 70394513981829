/**
 * @module Add Dropdown Controller
 * @since 3.76.0
 * @author Jacob Viertel <jv@onscreen.net>
 */

import {createDropdown} from '@acng/frontend-discovery';
import {defineRenderAttribute} from '@acng/frontend-stargazer';

const MODULE = 'layout/config/controller/dropdown';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

defineRenderAttribute(':dropdown', (element) => {
  DEBUG: if (VERBOSE || element.hasAttribute('debug')) console.info(MODULE, ':dropdown', {element});

  createDropdown(element);
});
