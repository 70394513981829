import {path} from '@acng/frontend-voyager';

import {mainView} from 'acng/layout/service/sequence.js';
import {userRoute} from 'acng/userPool/config/routes';

import {pinFeature} from './feature.js';

if (!pinFeature.isHidden) {
  userRoute.create(
    path('/pins'),
    mainView(pinFeature, 'list')
  );
}
