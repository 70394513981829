/**
 * `.layout-filled` and `.layout-empty` are mutually exclusive on the same element.
 * `.layout-open` and `.layout-closed` are mutually exclusive on the same element.
 *
 * @module
 * @author Jacob Viertel <jv@onscreen.net>
 * @since 3.84.0
 */

import {addClass, allAnimationsFinished, removeClass, toggleClass} from '@acng/frontend-bounty';
import {STYLE_ACTIVE, STYLE_INACTIVE} from '../config/style.js';
import {isUndefined} from 'angular';
import {keyIn} from '@acng/frontend-bounty/object.js';

export const STYLE_MENU_MAIN = 'layout-menu';

/**
 * `.layout-filled` is referencing or containing a *filled* list, which is **not empty**.
 */
export const STYLE_FILLED = 'layout-filled';

/**
 * `.layout-empty` is referencing or containing an *empty* list, which is **not filled**.
 */
export const STYLE_EMPTY = 'layout-empty';

export const STYLE_OPEN = 'layout-open';
export const STYLE_CLOSED = 'layout-closed';
export const STYLE_WUMPER = 'layout-wumper';
export const STYLE_BUSY = 'layout-busy';
export const STYLE_IDLE = 'layout-idle';

export {STYLE_ACTIVE, STYLE_INACTIVE};

const dominant = {
  [STYLE_OPEN]: STYLE_CLOSED,
  [STYLE_CLOSED]: STYLE_OPEN,
  [STYLE_FILLED]: STYLE_EMPTY,
  [STYLE_EMPTY]: STYLE_FILLED,
  [STYLE_ACTIVE]: STYLE_INACTIVE,
  [STYLE_INACTIVE]: STYLE_ACTIVE,
  [STYLE_BUSY]: STYLE_IDLE,
  [STYLE_IDLE]: STYLE_BUSY,
};

/**
 * @param {Element} element
 * @param {string} className
 * @param {boolean} [force]
 * @returns {boolean}
 */
export const swapClass = (element, className, force) => {
  const enabled = toggleClass(element, className, isUndefined(force) || force);

  if (keyIn(dominant, className)) {
    toggleClass(element, dominant[className], !enabled);
  }

  return enabled;
}

/**
 * @param {Element} element
 */
export const wumper = async (element) => {
  addClass(element, STYLE_WUMPER);

  await allAnimationsFinished(element);
  removeClass(element, STYLE_WUMPER);
};
