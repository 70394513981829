import {digest, errorTemplate, rootScope} from '@acng/frontend-stargazer';
import {rootRoute} from '@acng/frontend-voyager';
import {GlobalWatchGroup} from '@acng/frontend-relativity';
import {body, createDiv, removeChildNodes, setHtml} from '@acng/frontend-bounty';
import {ctxAuthUser} from 'acng/userPool/context/auth-user.js';
import {ctxLocale} from 'acng/locale/model/locale.js';
import {throttle} from '@acng/frontend-bounty/timing/throttle.js';
import {hookFeature} from '../config/feature.js';
import {portal} from './env.js';
import {fsk} from 'acng/userPool/fsk.js';

setHtml(errorTemplate, '<strong class="drf-notfound">🚧 ${message} 🚧</strong>');

/**
 * @param {angular.IRootScopeService} $rootScope
 * @param {angular.ICompileService} $compile
 * @param {angular.ILocationService} $location
 */
export const runBootstrap = ($rootScope, $compile, $location) => {
  /**
   * @type {angular.IScope}
   */
  let $scope;

  /**
   * @type {import("@acng/frontend-stargazer").Engine}
   */
  let engine;

  /**
   * @param {[
   *   typeof import("./env.js").authUser,
   *   import('acng/locale/model/locale.js').Language,
   * ]} values
   */
  const bootstrap = async ([authUser, locale]) => {
    removeChildNodes(div);
    engine?.disconnect();
    $scope?.$destroy();
    Object.assign(rootScope, {
      PORTAL: portal.shortcut.toLowerCase(),
      FSK: fsk.get(),
      LANG: locale,
      GUEST: !authUser,
      USERNAME: authUser?.nickname ?? "guest",
    });
    digest();
    rootRoute.reload($location.path());
    $scope = $rootScope.$new();
    engine = await hookFeature.render('body');
    DEBUG: console.warn("BOOTSTRAP", engine);
    $compile(engine.toElement(div))($scope);
    $scope.$digest();
  };

  const div = createDiv();
  body.prepend(div);

  GlobalWatchGroup(ctxAuthUser, ctxLocale)(throttle(bootstrap, 10, false));
};
