/**
 * @todo
 * - add stop condition: not intersecting
 * - use persistent engine and reuse it for each slide
 *   making `let currentTemplate` obsolete (engine.template)
 *   making `let currentSlide` obsolete (engine.hostElement)
 *   stargazer api has to support that
 *
 * @module
 * @author Jacob Viertel <jv@onscreen.net>
 * @since 3.87.0
 */

// bounty/std
import {noop, undefined} from '@acng/frontend-bounty/std/value.js';
import {push} from '@acng/frontend-bounty/std/array.js';
import {forOf} from '@acng/frontend-bounty/object.js';
import {whenAll} from '@acng/frontend-bounty/std/control.js';

// bounty/dom
import {debug} from '@acng/frontend-bounty/dom/debug.js';
import {TAGNAME_TEMPLATE, isHTMLElement} from '@acng/frontend-bounty/dom/type.js';
import {document} from '@acng/frontend-bounty/dom/document.js';
import {VISIBILITYCHANGE, off, on, once} from '@acng/frontend-bounty/dom/event.js';
import {ObserveAttributes, Size} from '@acng/frontend-bounty/dom/observe.js';
import {
  HTMLElement,
  connectedCallback,
  defineElement,
  disconnectedCallback,
} from '@acng/frontend-bounty/dom/custom.js';
import {CLASS, HIDDEN, TITLE, get, has, remove, set} from '@acng/frontend-bounty/dom/attribute.js';

// bounty/mixed
import {IS, NULLABLE, typeguard} from '@acng/frontend-bounty/typeguard.js';
import {throttle} from '@acng/frontend-bounty/timing/throttle.js';
import {append, clearTimeout, createDiv, hasClass, setStyle, setTimeout} from '@acng/frontend-bounty';
import {now} from '@acng/frontend-bounty/timing/now.js';

// stargazer
import {Engine, defineRegistryElement} from '@acng/frontend-stargazer';

// nova
import {SLIDESHOW_SLIDE, __SLIDESHOW_WIDTH} from '../style/slideshow.js';
import {observe, fromAttribute, queryTitle, allScenes} from '../service/sequence.js';
import {enter, leave} from '../service/animate-class.js';
import {STYLE_BUSY, STYLE_EMPTY, STYLE_OPEN, swapClass} from '../service/style.js';

/**
 * ### Attributes
 * - `scene` (optional)
 * - `data-delay` (optional)
 *
 * @see {@link DATA_DELAY | `data-delay`}
 *
 * @example
 * ```html
 * <nova-slideshow>
 *   <template>Hallo Welt</template>
 *   <template>Yo!</template>
 * </nova-slideshow>
 * ```
 *
 * @group DOM Element
 */
export const NOVA_SLIDESHOW = 'nova-slideshow';

/**
 * delay time in seconds attribute
 * it is a data-attribute because it may be set on a scene `<template>` element too.
 *
 * ### Elements
 * - `<nova-slideshow>`
 * - `<template>` as child of `<nova-slideshow>`
 *
 * @see {@link ONSW_SLIDESHOW | `<nova-slideshow>`}
 *
 * @example
 * ```html
 * <nova-slideshow data-delay="5">
 *   <template> Show for default time </template>
 *   <template data-delay="30"> Show for long time </template>
 *   <template data-delay="2"> Show only short time </template>
 * </nova-slideshow>
 * ```
 *
 * @group DOM Attribute
 */
export const DATA_DELAY = 'data-delay';

/**
 * @group DOM Attribute
 */
export const SLIDESHOW_INDEX = 'slideshow-index';

/**
 * @todo
 * - Consider just using {@link TITLE}.
 *
 * @group DOM Attribute
 */
export const COMMON_TITLE = 'common-title';

defineRegistryElement(NOVA_SLIDESHOW, (name) => {
  /**
   * delay time in seconds
   */
  const DEFAULT_DELAY = 5;
  const TEST_DYNAMIC = false;

  class SlideshowElement extends HTMLElement {
    #disco = noop;

    [connectedCallback]() {
      let running = false;

      /**
       * @type {?HTMLTemplateElement}
       */
      let currentTemplate = null;

      /**
       * @type {?HTMLElement}
       */
      let currentSlide = null;

      let timeout = 0;

      let lastSwapAt = now();

      const commonTitle = get(this, COMMON_TITLE);

      const observeSize = Size((slideshow, width) => {
        DEBUG: if (debug(slideshow)) console.debug('size change', {slideshow, width});
        setStyle(this, __SLIDESHOW_WIDTH, `${width}px`);

        if (!running && width) {
          start();
        } else if (!width) {
          stop();
        }
      });

      const observeScene = ObserveAttributes([CLASS], (scene) => {
        ASSERT: typeguard('', scene, IS(HTMLTemplateElement));

        if (hasClass(scene, STYLE_OPEN) && scene != currentTemplate) {
          changeSlide(scene);
        }
      });

      const start = () => {
        if (this.contains(document.activeElement)) {
          return;
        }

        running = true;
        if (!currentSlide) {
          DEBUG: if (debug(this)) console.info('start immediate', {slideshow: this});
          changeSlide();
        } else {
          DEBUG: if (debug(this)) console.info('start delayed', {slideshow: this});
          changeSlideDelayed();
        }
      };

      const stop = () => {
        DEBUG: if (debug(this)) console.info('stop', {slideshow: this});
        running = false;
        clearTimeout(timeout);
      };

      const sequence = fromAttribute(this);

      if (TEST_DYNAMIC) {
        import('@acng/frontend-bounty/std/number.js').then(({seq}) =>
          seq(5, 1).forEach((i) => {
            const template = document.createElement('template');
            template.innerHTML = `<a title="slide${i}">#{ image: welcome.slide-${i} }</a>`;
            setTimeout(() => sequence.content.append(template), 1000 * i);
            setTimeout(() => template.remove(), 1000 * (20 + i));
            return template;
          })
        );
      }

      /**
       * @type {HTMLTemplateElement[]}
       */
      const ownTemplates = [];

      for (const node of this.children) {
        if (isHTMLElement(node, TAGNAME_TEMPLATE)) {
          DEBUG: if (debug(this)) console.info('add own template', {slideshow: this, node});
          if (commonTitle && !has(node, TITLE)) {
            set(node, COMMON_TITLE, commonTitle);
          }

          push(ownTemplates, node);
        } else {
          DEBUG: if (debug(this)) console.debug('do not add child', {slideshow: this, node});
        }
      }

      append(sequence.content, ...ownTemplates);
      allScenes(sequence, observeScene);

      const observerDisconnect = observe(
        sequence,
        (addedTemplates) => {
          DEBUG: if (debug(this)) {
            if (addedTemplates.length == 1) {
              console.debug('observed new template', {slideshow: this, sequence, addedTemplates});
            } else {
              console.info('observed, change to first of them', {
                slideshow: this,
                sequence,
                addedTemplates,
              });
            }
          }
          forOf(addedTemplates, observeScene);
          changeSlide(addedTemplates[0]);
        },
        (removedTemplates) => {
          for (const template of removedTemplates) {
            DEBUG: if (debug(this)) {
              if (currentSlide == template) {
                console.info('observed remove current', {slideshow: this, template});
              } else {
                console.debug('observed remove', {slideshow: this, template});
              }
            }
            if (currentTemplate == template) {
              changeSlide();
            }
          }
        }
      );

      /**
       * @param {?HTMLTemplateElement} template
       * @returns {?HTMLTemplateElement}
       */
      const searchValidNext = (template) => {
        if (!currentTemplate && sequence.content.children.length) {
          const resumeIndex = Number(get(sequence, SLIDESHOW_INDEX)) ?? 0;
          const resumeScene = sequence.content.children[resumeIndex];

          ASSERT: typeguard('resume scene', resumeScene, NULLABLE(IS(HTMLTemplateElement)));
          DEBUG: console.info('no current scene, resume', {
            slideshow: this,
            sequence,
            resumeIndex,
            resumeScene,
          });

          if (resumeScene) {
            return resumeScene;
          }
        }

        let found = template?.nextElementSibling ?? sequence.content.firstElementChild;
        ASSERT: typeguard('content', found, NULLABLE(IS(HTMLTemplateElement)));

        if (found != template && found && !found.content.childNodes.length) {
          return searchValidNext(found);
        }

        return found;
      };

      /**
       * @param {?HTMLTemplateElement} [nextTemplate]
       */
      const changeSlide = (nextTemplate) => {
        clearTimeout(timeout);

        if (document[HIDDEN]) {
          // isHibernated(wakeupCallback)
          console.debug('document is hidden, slideshow stopped', {slideshow: this});
          stop();
          once(document, VISIBILITYCHANGE, start);
          return;
        }

        nextTemplate ??= searchValidNext(currentTemplate);

        if (nextTemplate == currentTemplate) {
          DEBUG: if (debug(this)) {
            console.info('goto same page, slideshow stopped', {slideshow: this, currentTemplate});
          }
          return;
        }

        swapClass(this, STYLE_EMPTY, !sequence.content.children.length);
        swap(nextTemplate);
      };

      const changeSlideDelayed = () => {
        const delay =
          Number(
            (currentTemplate && get(currentTemplate, DATA_DELAY)) ?? get(this, DATA_DELAY) ?? DEFAULT_DELAY
          ) * 1000;
        clearTimeout(timeout);
        timeout = setTimeout(changeSlide, delay - (now() - lastSwapAt));
      };

      const swap = throttle(
        /**
         * @param {?HTMLTemplateElement} [nextTemplate]
         */
        async (nextTemplate) => {
          const previousTemplate = currentTemplate;
          swapClass(this, STYLE_BUSY, true);
          /**
           * @type {?HTMLElement}
           */
          let previousSlide = currentSlide;
          currentSlide = null;
          currentTemplate = nextTemplate ?? null;

          DEBUG: if (debug(this) && !nextTemplate) {
            console.info('no next, no timeout', {slideshow: this, previousSlide});
          }

          if (nextTemplate) {
            currentSlide = createDiv(SLIDESHOW_SLIDE);
            swapClass(nextTemplate, STYLE_BUSY, true);
            swapClass(nextTemplate, STYLE_OPEN, true);

            DEBUG: if (debug(this)) {
              console.info('await swap', {slideshow: this, previousSlide, currentSlide});
            }

            const scenes = sequence.content.children;
            const nextIndex = (() => {
              for (let i = scenes.length - 1; i >= 0; i--) {
                if (scenes[i] == nextTemplate) {
                  return i;
                }
              }
            })();

            const parser = new Engine(nextTemplate);
            await parser.lookup();
            this.append(currentSlide);
            parser.toElement(currentSlide);
            lastSwapAt = now();

            if (nextTemplate[TITLE]) {
              currentSlide[TITLE] = nextTemplate[TITLE];
            } else {
              const title = queryTitle(currentSlide) ?? get(this, COMMON_TITLE);

              if (title) {
                currentSlide[TITLE] = title;
                nextTemplate[TITLE] = title;
              }
            }

            set(sequence, SLIDESHOW_INDEX, `${nextIndex}`);

            swapClass(nextTemplate, STYLE_BUSY, false);

            DEBUG: if (debug(this)) {
              console.info('swap complete', {slideshow: this, previousSlide, currentSlide});
            }

            if (previousTemplate) {
              swapClass(previousTemplate, STYLE_OPEN, false);
            }

            if (running) {
              changeSlideDelayed();
            }
          }

          await whenAll([
            previousSlide ? leave(previousSlide) : undefined,
            previousSlide && currentSlide ? enter(currentSlide) : undefined,
          ]);

          swapClass(this, STYLE_BUSY, false);
        }
      );

      DEBUG: if (debug(this)) swap.enableDebug(this.getAttribute('scene') ?? 'none');

      observeSize(this);

      on(this, 'mouseenter', stop);
      on(this, 'mouseleave', start);
      on(this, 'focusin', stop);
      on(this, 'focusout', start);

      this.#disco = () => {
        // TODO clean up child nodes correctly
        off(this, 'mouseenter', stop);
        off(this, 'mouseleave', start);
        off(this, 'focusin', stop);
        off(this, 'focusout', start);
        observeSize(null);
        observeScene(null);
        observerDisconnect();
        stop();
        append(this, ...ownTemplates);
      };
    }

    [disconnectedCallback]() {
      this.#disco();
    }
  }

  defineElement(name, SlideshowElement);
});
