// bounty
import {MapStore, remove} from '@acng/frontend-bounty/collection.js';
import {join} from '@acng/frontend-bounty/std/array.js';
import {replaceChildren} from '@acng/frontend-bounty/dom/type.js';
import {split} from '@acng/frontend-bounty/std/string.js';
import {fetchHTML, fetchSVG} from '@acng/frontend-bounty/fetch';
// stargazer
import {Engine, spawn, root, content, source, rootScope, template} from '@acng/frontend-stargazer';

import {INHERIT, asset, hasFeature, media} from '../service/env.js';
import {getLocale} from 'acng/locale/model/locale.js';
import {popup} from '@acng/frontend-discovery';
import {
  TAGNAME_TEMPLATE,
  addClass,
  append,
  createDiv,
  createElement,
  document,
  documentElement,
  isString,
  joinArray,
  loadImage,
  queryAll,
  setId,
} from '@acng/frontend-bounty';
import {createCacheMap} from '@acng/frontend-bounty/cache';

import {t} from 'acng/locale/config/translate.js';
import {getIcon} from '../service/icon.js';

const cache = createCacheMap();

(() => {
  const IMAGE = 'image';
  const image = spawn(root, IMAGE);
  const preload = spawn(root, 'preload');
  const icon = spawn(root, 'icon');

  /** @type {(path: string) => Promise<void>} */
  const imageCache = MapStore(async (alias, cache) => {
    /** @type {{default: (config: {}) => string}} */
    const source = await import(`${media.assets_ext}/img/${alias}.js`),
      path = split(alias, '/'),
      element = await loadImage(source.default(rootScope)),
      putImage = spawn(image, ...path),
      putPreload = spawn(preload, ...path);

    addClass(element, IMAGE, join(path, '-'));
    replaceChildren(content(putImage), element);
    replaceChildren(content(putPreload), element.src);
    remove(cache, alias);
  });

  /**
   * @param {string[]} path
   * @returns {Promise<void>}
   */
  const insertImage = (path) => imageCache(join(path, '/'));

  source(image, insertImage);
  source(preload, insertImage);

  source(icon, ([featureName, imageName], insert) => {
    if (!imageName) {
      return cache(`icon:${featureName}`, async () => {
        insert(await getIcon(featureName));
      });
    }
    const src = require(`../../../${documentElement.dataset.portal}/img/${featureName}/${imageName}.svg`);
    return cache(src, async () =>
      insert(document.importNode((await fetchSVG(asset(src))).documentElement, true))
    );
  });
})();

/**
 * @param {string} name
 * @returns {HTMLTemplateElement}
 */
export const createFrontendFeature = (name) => {
  const engine = spawn(root, name);
  source(engine, HtmlTemplateLoader(name));
  return template(engine);
};

export class Feature extends Engine {
  /**
   * @param {string} name
   */
  constructor(name, disabled = !hasFeature(name)) {
    super(root, name);
    this.source = HtmlTemplateLoader(name);
    this.hidden = disabled;
  }

  /**
   * @param {string[] | string} src
   * @param {Record<string, string>} [params]
   * @todo TODO bounty add splitArray
   */
  async translate(src, params) {
    if (isString(src)) {
      src = src.split('.');
    }
    return await t([this.name, ...src], params);
  }

  /**
   * @template [T = void]
   * @param {?Element} host
   * @param {string} name
   * @param {(close: ((value?: T) => void)) => Record<string, any>} params
   * @param {boolean} [modal]
   * @returns {Promise<T>}
   */
  async popup(host, name, params, modal) {
    const div = createDiv();
    const template = await this.render(name);

    template.toElement(
      div,
      params((value) => popup().close(div, value))
    );
    const res = popup(host ?? undefined)[modal ? 'showModal' : 'show'](div);

    // @ts-expect-error
    return await res;
  }
}

/**
 * @param {string} name
 * @returns {import('@acng/frontend-stargazer/types').ResourceLoader}
 */
const HtmlTemplateLoader =
  (name) =>
  async ([templateName], insert) => {
    const module = await import('../../html.js');
    const src = asset(module.getTemplateSource(INHERIT, getLocale(), [name, templateName]));

    return cache(src, async () => {
      const doc = await fetchHTML(src);
      addLegacyStylesOnDemand(doc, templateName);
      await insert(doc);
    });
  };

/**
 * @deprecated
 * @param {Document} doc
 * @param {string} templateName
 */
const addLegacyStylesOnDemand = (doc, templateName) => {
  const templates = queryAll(TAGNAME_TEMPLATE, doc);

  switch (templates.length) {
    case 0:
      for (const child of doc.body.childNodes) {
        if (child instanceof Element && child.matches('.box')) {
          const dashName = toDash(templateName);
          addClass(child, `${dashName}-box`);
          for (const element of queryAll(':scope > *', child)) {
            addClass(element, `${dashName}-widget`, templateName);
          }
        }
      }
      const template = createElement(TAGNAME_TEMPLATE);
      setId(template, templateName);
      append(template.content, ...doc.body.childNodes);
      append(doc.head, template);
      break;
    case 1:
      setId(templates[0], templateName);
      break;
  }
};

/**
 * @deprecated
 * @param {string} str
 */
const toDash = (str) => str.replace(/([A-Z])/g, ($1) => `-${$1.toLowerCase()}`);
