/**
 * @todo
 * - Fork `<nova-shuffle>` using `ctxList` or so
 *
 * @see onsw-tiles todo 1
 */

// bounty
import {HTMLElement, con, def, dis} from '@acng/frontend-bounty/dom/custom.js';
import {now} from '@acng/frontend-bounty/std/date.js';
import {on, off} from '@acng/frontend-bounty/dom/event.js';
import {rand} from '@acng/frontend-bounty/std/number.js';
import {
  setStyle,
  addClass,
  createDiv,
  sliceOfArray,
  createTemplate,
  loadImage,
  whenAll,
  setText,
  trigger,
  onClick,
  clearTimeout,
} from '@acng/frontend-bounty';
// stargazer & discovery
import {defineRegistryElement} from '@acng/frontend-stargazer';
import {createPerceptiveHTML, createStream} from '@acng/frontend-discovery';
// enterprise
import {inject} from 'acng/core/service/ng';
import {coreFeature} from 'acng/core/config/feature';
import {media} from 'acng/core/service/env';
import {AOTM_TYPE, LOTM_TYPE, getList, sort} from '../service/otm';
import {shuffle} from 'acng/core/factory/helper';
import {isOnline} from '../service/online';

const VERBOSE_DEBUG = false;

defineRegistryElement('onsw-amateur-otm-shuffle', (name) => {
  const Amateur = inject('Amateur');

  def(name, class extends HTMLElement {
    [con]() {
      connect(this, Amateur);
    }
    [dis]() {
      trigger(this, 'stargazer-disconnect', undefined, false);
    }
  });
});

/**
 * @param {HTMLElement} host
 * @param {import('acng/amateurPool/factory/Amateur').AmateurConstructor} Amateur
 */
const connect = async (host, Amateur) => {
  let cols = 5;
  /** @type {HTMLElement | null} */
  let target = null;
  let timeout = 0;
  let lastSwapAt = now();

  addClass(host, 'warp-row-inset');
  const stream = createStream(/** @type {import('../types').Otm[]} */ ([]));
  const perceptive = createPerceptiveHTML(host, stream, async (data, loader) => {
    const imageSrc = `${media.content.pictures}${data.image}`;
    const [amateur, years] = await whenAll([
      Amateur.get(data.amateurId),
      coreFeature.translate('years'),
      loadImage(imageSrc),
    ]);
    if (target) {
      setStyle(target, 'z-index', '1');
    }
    target = loader.target;
    if (!target) {
      return;
    }
    const nickname = createDiv('name');
    const age = createDiv('age');
    const template = createTemplate('', nickname, age);
    addClass(template, 'amateurPool-otm-shuffle');
    setText(nickname, amateur.getNickname());
    setText(age, `${amateur.age} ${years}`);
    setStyle(target, '--otm-color-start', `${data.color}00`);
    setStyle(target, '--otm-color-end', `${data.color}C0`);
    setStyle(target, '--otm-image-url', `url(${imageSrc})`);
    onClick(target, () => amateur.goto());
    return template;
  });

  DEBUG: if (VERBOSE_DEBUG) perceptive.enableDebug('otm-shuffle');

  const [lotmList, aotmList] = await whenAll([getList(host, LOTM_TYPE), getList(host, AOTM_TYPE)]);
  const list = [...lotmList, ...aotmList];

  shuffle(list);
  sort(list, isOnline);
  sort(list, inject('Livecam').isOnline);

  const ro = new ResizeObserver((entries) => {
    const width = entries[0].contentRect.width;
    if (!width) {
      stop();
      perceptive.disconnect();
    } else {
      cols = Math.floor(width / 120);
      setStyle(host, '--warp-width', `calc((${width}px - var(--warp-gap) * ${cols - 1}) / ${cols})`);
      setStyle(host, '--warp-limit', `${cols}`);
      stream.streamReset(sliceOfArray(list));
      perceptive.connect();
      start();
    }
  });
  ro.observe(host);

  const move = () => {
    const j = Math.floor(list.length / 2);
    if (Math.random() < 0.5) {
      list.splice(
        2 + Math.floor(Math.random() * (cols - 2)), //
        0,
        list.splice(j, 1)[0]
      );
      // @ts-ignore
      list.push(list.shift());
    } else {
      list.splice(
        1 + Math.floor(Math.random() * (cols - 2)), //
        0,
        list.splice(j, 1)[0]
      );
    }
    stream.streamReset(sliceOfArray(list));
    perceptive.connect();
    lastSwapAt = now();
    start();
  };

  const start = () => {
    clearTimeout(timeout);
    timeout = setTimeout(move, rand(80, 50) ** 2 - now() + lastSwapAt);
  };

  const stop = () => {
    clearTimeout(timeout);
  };

  on(host, 'mouseenter', stop);
  on(host, 'mouseleave', start);
  on(host, 'stargazer-disconnect', () => {
    stop();
    perceptive.disconnect();
    off(host, 'mouseenter', stop);
    off(host, 'mouseleave', start);
  });
};
