// bounty
import {CLICK, off, on} from '@acng/frontend-bounty/dom/event.js';
import {STRING, TUPLE, UNKNOWN, typeguard} from '@acng/frontend-bounty/typeguard.js';
// stargazer, relativity
import {localValue} from '@acng/frontend-relativity';
import {Engine, defineRegistryAttribute, defineRenderAttribute} from '@acng/frontend-stargazer';
// enterprise
import {ctxChallenge} from 'acng/achievement/context/challenge.js';
import {USE_CHALLENGE} from 'acng/achievement/attribute/use-challenge.js';
import {debug} from '@acng/frontend-bounty/dom/debug.js';
import {media} from 'acng/core/service/env';

/**
 * Start a *special game* when the element is clicked.
 *
 * An optionally specified {@link USE_CHALLENGE | challenge}
 * will be achieved once the game is won.
 *
 * @example
 * ```html
 * <button click-special-game="valentine"> Play </button>
 * ```
 * @group DOM Attribute
 */
export const CLICK_SPECIAL_GAME = 'click-special-game';

defineRegistryAttribute(CLICK_SPECIAL_GAME, (attributeName) => {
  const syntax = /^(\w+)$/;

  /**
   * @param {Element} element
   * @param {string} moduleName
   */
  const start = async (element, moduleName) => {
    /**
     * @type {import("../types").GameModule}
     */
    const module = await import(`../games/${moduleName}.js`);

    await module.start(`${media.assets_ext}/specials/`, Engine.root, async () => {
      try {
        const challenge = localValue(element, ctxChallenge);

        await challenge.achieve(element);
      } catch (reason) {
        DEBUG: if (debug(element)) console.info('no challenge specified', {element});
      }
    });
  };

  defineRenderAttribute(attributeName, (element, value) => {
    const click = async () => {
      off(element, CLICK, click);
      const match = syntax.exec(value);
      ASSERT: typeguard(`[${attributeName}="${value}"] syntax`, match, TUPLE(UNKNOWN, STRING));

      await start(element, match[1]);
    };

    on(element, CLICK, click);
  });
});
