// bounty
import {HIDDEN} from '@acng/frontend-bounty/dom/attribute.js';
import {extend} from '@acng/frontend-bounty/object.js';
import {IS, typeguard} from '@acng/frontend-bounty/typeguard.js';
// stargazer
import {Engine} from '@acng/frontend-stargazer';
// enterprise
import {hasFeature, media} from 'acng/core/service/env.js';

(() => {
  const SPECIAL = 'special';
  const specials = Engine.root.spawn(SPECIAL);

  specials[HIDDEN] = !hasFeature(SPECIAL);
  //specials.template.setAttribute('debug', '');

  specials.source = async ([name]) => {
    /**
     * @type {Module}
     */
    const {source, config} = await import(`${media.assets_ext}/specials/${name}/${SPECIAL}.js`);
    ASSERT: if (!(config instanceof Function) || fetch.length != 1) console.warn('strange', {config});

    const htmlFile = await source();
    ASSERT: typeguard('special source', htmlFile, IS(URL));

    const special = specials.spawn(name);
    const scope = extend(Engine.rootScope, config(Engine.rootScope));

    special.sourceScope = scope;
    await special.fetch(htmlFile, null);
  };
})();

/**
 * TODO Put into *Stargazer*
 *
 * @typedef Module
 * @property {(...path: string[]) => Promise<URL | void>} source
 * @property {(config: {}) => {}} config
 */
