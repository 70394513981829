// bounty
import {append} from '@acng/frontend-bounty';
import {debug} from '@acng/frontend-bounty/dom/debug.js';
import {CLICK, off, on} from '@acng/frontend-bounty/dom/event.js';
// stargazer
import {Engine, resolve} from '@acng/frontend-stargazer';
import {defineRegistryAttribute, defineRenderAttribute} from '@acng/frontend-stargazer';

/**
 *
 *
 * @group DOM Attribute
 */
export const CLICK_TOGGLE = 'click-toggle';

defineRegistryAttribute(CLICK_TOGGLE, (name) => {
  defineRenderAttribute(name, (element, alias, scope) => {
    /**
     * @type {Engine | undefined}
     */
    let engine;

    const click = async () => {
      off(element, CLICK, click);
      DEBUG: if (debug(element)) console.debug('lookup', {element, alias});
      engine = new Engine(await Engine.root.lookup(...resolve(alias)));
      DEBUG: if (debug(element)) console.log('link engine', {element, engine});

      append(element, engine.toFragment(scope));
      engine.connect();
    };

    on(element, CLICK, click);

    return () => () => {
      off(element, CLICK, click);
      engine?.disconnect();
    };
  });
});
