/**
 * @bug
 * 1. The internal templates are not recompiled when, for example, the language changes.
 *
 * @todo
 * 1. These are Stargazer engines! (synchronously available resources) extend from it.
 * 2. Implement dynamic recompile, when the language changes etc.
 *    Consider making a `ctxRecompile` which groups the recompile rules.
 *
 * @module
 * @author Jacob Viertel <jv@onscreen.net>
 */

import {Engine, content, create, root, source, spawn, template} from '@acng/frontend-stargazer';
import {middleware, rootRoute} from '@acng/frontend-voyager';
import {append, documentElement} from '@acng/frontend-bounty';
import {off, on} from '@acng/frontend-bounty/dom/event.js';
import {createWithIdAndNodes} from '@acng/frontend-bounty/dom/template.js';
import {join} from '@acng/frontend-bounty/std/array.js';
import {ObserveChildList} from '@acng/frontend-bounty/dom/observe.js';
import {get, TITLE, ALT} from '@acng/frontend-bounty/dom/attribute.js';
import {attributeSelector, closestAttribute, query} from '@acng/frontend-bounty/dom/query.js';
import {IS, NODELIST, NULLABLE, typeguard} from '@acng/frontend-bounty/typeguard.js';
import {SEQUENCE_NAME} from '../attribute/sequence-name.js';

/**
 * All sequences will be removed on digest.
 */
const sequences = spawn(root, 'sequences');

source(sequences, async ([name]) => {

});

/**
 * @param {?string} key
 * @returns {import('@acng/frontend-stargazer').InternalEngine}
 */
export const getSequence = (key) => key ? spawn(sequences, key) : create();

/**
 * TODO child resources iterator in stargazer?
 * @param {Sequence} sequence
 * @param {(scene: HTMLTemplateElement) => void} callback
 * @returns {void}
 */
export const allScenes = (sequence, callback) => {
  for (const scene of sequence.content.children) {
    ASSERT: typeguard('scene', scene, IS(HTMLTemplateElement));
    callback(scene);
  }
};

/**
 * @example
 * ```js
 * class extends HTMLElement {
 *   connectedCallback() {
 *     const sequence = fromAttribute(this);
 *     ...
 *   }
 * }
 * ```
 *
 * @param {Element} element
 *
 * @returns {Sequence}
 * Well known sequence named by `sequence-name` attributes value.
 * Or, if the `sequence-name` attribute is not specified, return a new sequence.
 */
export const fromAttribute = (element) => {
  const closest = closestAttribute(documentElement, element, SEQUENCE_NAME);

  return closest ? template(getSequence(get(closest, SEQUENCE_NAME))) : createWithIdAndNodes('');
};

/**
 * @param {ParentNode} parentNode
 * @returns {?string}
 */
export const queryTitle = (parentNode) => {
  const selectors = `${attributeSelector(TITLE)},${attributeSelector(ALT)}`;
  const element = query(parentNode, selectors);

  return element && (get(element, TITLE) ?? get(element, ALT));
};

/**
 * @param {Sequence} sequence
 * @param {(templates: NodeListOf<HTMLTemplateElement>, nextTemplate: ?HTMLTemplateElement) => void} addedCallback
 * @param {(templateS: NodeListOf<HTMLTemplateElement>, nextTemplate: ?HTMLTemplateElement) => void} [removedCallback]
 * @returns {() => void} Disconnect observer function
 */
export const observe = (sequence, addedCallback, removedCallback) => {
  const observe = ObserveChildList(
    (scenes, nextScene) => {
      ASSERT: {
        typeguard('', scenes, NODELIST(HTMLTemplateElement));
        typeguard('', nextScene, NULLABLE(IS(HTMLTemplateElement)));
      }
      addedCallback(scenes, nextScene);
    },
    (scenes, nextScene) => {
      ASSERT: {
        typeguard('', scenes, NODELIST(HTMLTemplateElement));
        typeguard('', nextScene, NULLABLE(IS(HTMLTemplateElement)));
      }
      removedCallback?.(scenes, nextScene);
    }
  );

  observe(sequence.content);

  return () => observe(null);
};

/**
 * @param {string} name
 */
const ViewMiddleware = (name) => {

  /**
   * @param {Engine | HTMLTemplateElement} feature
   * @param {string[]} path
   */
  return (feature, ...path) => {
    const fullPath = [feature.id, ...path];
    const id = join(fullPath, '-');
    const template = createWithIdAndNodes(id, `#{${join(fullPath, '.')}}`);

    /**
     * @param {import("@acng/frontend-voyager").Route} route
     */
    return (route) =>
      middleware(() => {
        const enter = () => {
          const sequence = getSequence(name);
          DEBUG: route.VERBOSE && console.debug(`voyager/config view "${name}" enter`, {feature, path});
          append(content(sequence), template);
          //console.log('middleware sequence append', name, sequence, template);
          on(
            route,
            'leave',
            () => {
              DEBUG: route.VERBOSE && console.debug(`voyager/config view "${name}" leave`, {feature, path});
              template.remove();
            },
            {once: true}
          );
        };
        on(route, 'enter', enter, {once: true});
        on(
          rootRoute,
          'settled',
          () => {
            DEBUG: route.VERBOSE && console.debug(`voyager/config view "${name}" settled`);
            off(route, 'enter', enter);
          },
          {once: true}
        );
      })(route);
  };
};

export const mainView = ViewMiddleware('main');
export const stickyView = ViewMiddleware('sticky');
export const footerView = ViewMiddleware('footer');

export const getSlideshowUserHome = () => new Engine(getSequence('slideshow-user-home'));
export const getSlideshowSidebar = () => new Engine(getSequence('slideshow-sidebar'));

/**
 * Bluaa
 * @typedef {HTMLTemplateElement} Sequence
 */

/**
 * Aasd
 * @typedef {HTMLTemplateElement} Scene
 */
