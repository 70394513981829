import {
  middleware,
  createMany,
  alias,
  incomplete,
  index,
  path,
  expr,
  listen,
  create,
} from '@acng/frontend-voyager';

import {hookFeature} from 'acng/core/config/feature';
import {authUser} from 'acng/core/service/env';
import {hasMyPortal} from 'acng/core/service/env';
import {rootRoute} from 'acng/core/config/routes';
import {mainView, stickyView} from 'acng/layout/service/sequence.js';

import {fsk} from '../fsk';
import {onlyUser, onlyGuest, isPortal} from './middleware';
import {inject} from 'acng/core/service/ng';
import {userFeature, userPoolFeature} from './feature';

// Add user-service and fsk-level to globals
rootRoute.config(
  middleware((next) => {
    next.user = inject('user');
    next.fsk = fsk.get(); // TODO reload on change?
  })
);

/**
 * Trunk with routes only accessible to authorized users
 */
export const userRoute = rootRoute.create(
  onlyUser,
  incomplete,
  alias('user'),
  createMany(
    [isPortal, incomplete, alias('isPortal')],
    [path('/'), index, stickyView(userFeature, 'home-header'), mainView(userFeature, 'home'), alias('home')],
    [path('/2fa'), mainView(userPoolFeature, '2fa')],
    [path('/stock'), mainView(hookFeature, 'stock'), alias('stock')]
  )
);

export const settingsBaseRoute = userRoute.create(
  path('/profile'), //
  incomplete,
  createMany(
    [path('/image/{image}'), alias('profileImageEditor')],
    [
      index,
      mainView(userPoolFeature, 'profile'),
      listen('enter', () => inject('user').trackActivity()),
    ]
  )
);

// Create and export sidebar route
export const sidebarRoute = userRoute.create(
  incomplete,
  expr(/^\/(?!hotornot$|livecams|profile$)/),
  hasMyPortal ? expr(/^\/(?!$)/) : () => {},
  alias('sidebar'),
  create(incomplete, expr(/^\/sedcard/), alias('sedcardSidebar')),
);

/**
 * Trunk with routes only accessible to guests
 */
export const guestRoute = rootRoute.create(
  onlyGuest,
  incomplete,
  alias('guest'),
  createMany(
    [path('/'), index, mainView(hookFeature, 'homeGuest'), alias('homeGuest')], //
    [path('/signup'), mainView(hookFeature, 'signup'), alias('signup')],
    [path('/passwordReset'), index, alias('passwordReset')]
  )
);

// Further routes accessible both for guests and users
rootRoute.createMany(
  [path('/passwordReset/{passwordReset}'), alias('passwordSetForm')],
  [path('/sentConfirmMail'), alias('confirmMail')],
  [path('/login'), alias('pageLogin')],
  [path('/newsletter/unsubscribe'), mainView(userPoolFeature, 'mailing-config')]
);

// Show signup overlay for unresolved routes when not logged in.
runSignupOverlay.$inject = ['user'];
/**
 * @param {import('acng/userPool/factory/user').User} user
 */
export function runSignupOverlay(user) {
  rootRoute.config(
    listen(
      'fail',
      /** @param {Event} evt */
      async (evt) => {
        /** @type {string} */
        const pathname = /** @type {CustomEvent} */ (evt).detail.pathname;
        // Do not show the overlay when failing on empty pathname.
        if (pathname) {
          user.guestSignup();
        }
      }
    )
  );
}

// Reload on user login
runReloadOnLogin.$inject = ['$rootScope', '$location'];
/**
 * @param {angular.IRootScopeService} $rootScope -
 * @param {angular.ILocationService} $location -
 */
export function runReloadOnLogin($rootScope, $location) {
  if (!authUser) {
    const unreg = $rootScope.$on('userPool.login.success', () => {
      unreg();
      rootRoute.reload($location.path());
    });
  }
}
