import angular from 'angular';
import {listen} from "acng/core/context/event-bus.js";
import {typeguard, EVENTBUS_RATING} from "../service/typeguard.js";
import {inject} from 'acng/core/service/ng.js';

angular.module('rating')
  .directive('onswRating', ['user', '$injector', function (user, $injector) {

    /**
     * @type {angular.IDirectiveLinkFn<angular.IScope & {
     *   personalRating: number | undefined;
     *   itemId: string;
     *   type: "Picture" | "Movie";
     *   interactive: string;
     *   item: import('acng/picturePool/model/picture').Picture | import('acng/moviePool/factory/Movie').IMovie;
     * }>}
     */
    function link(scope, element) {

      var currentRating = 0;
      var container = element.find('.outer');
      var rating = container.find('.inner');
      var busy = false;
      var rateable = false;

      /*function enter() {
      if (!rateable || !!scope.personalRating || busy) return;
      element.addClass('voting')
    }
     function leave() {
      if (!rateable || !!scope.personalRating || busy) return;
      element.removeClass('voting');
      currentRating = 0;
      rating.css({width: getWidth() + '%'})
    }
     function move(ev) {
      if (!rateable || !!scope.personalRating || busy) return;
      var offset = ev.pageX - angular.element(ev.currentTarget).offset().left;
      currentRating = Math.ceil(offset / (container.width() / 5));
      rating.css({width: getWidth(currentRating) + '%'});
    }
     function click(ev) {
      if (!rateable || !!scope.personalRating || busy || !scope.item) return;
      busy = true;
       scope.item.rate(currentRating).then(function (res) {
        scope.item.rating = res;
        scope.personalRating = currentRating;
        element.addClass('voted');
        element.removeClass('voting');
        rating.css({width: getWidth(currentRating) + '%'});
      }).finally(function () {
        busy = false;
      });
      ev.stopPropagation();
      ev.preventDefault();
    }*/





      element.addClass('invalid');
      scope.$watch('itemId', function (id) {
        busy = true;
        rateable = false;
        scope.personalRating = undefined;
        element[0].classList.remove('voted', 'rateable', 'ready', 'voting', 'error');

        if (!id) return;
        return inject(scope.type)
          .get(id)
          .then(function (item) {
            scope.item = item;
            if (user.isInSplittyGroup(64, 1)) {
              scope.rating = Number(item.media_rating_test ?? 0) / 2;
              return;
            }

            scope.rating = item.rating;
            if (user.guest) return;
            //checkRateable();
            return item.getRating().then(function (res) {
              if (res === null) return;
              scope.personalRating = res;
              element.addClass('voted');
            });
          })
          .finally(function () {
            busy = false;
            rating.css({ width: getWidth() + '%' });
            element.addClass('ready');
            element[0].classList.remove('invalid', 'busy');
          });
      });

      //sock.$on(scope, 'stock.update', checkRateable);

      scope.$on('$destroy', listen('gallery.rating', (ev) => {
        ASSERT: typeguard('', ev, EVENTBUS_RATING());
        const gallery = ev.gallery;

        if (scope.item.set_id == gallery.set_id) {
          scope.item.rating = gallery.rating;
          scope.personalRating = gallery.personal_rating;
          rating.css({ width: getWidth(gallery.personal_rating) + '%' });
          element.addClass('voted');
        }
      }));

      /*function checkRateable() {
      if (rateable) return;
      scope.item.rateable().then(function () {
        rateable = true;
      }).catch(function () {
        rateable = false;
      }).finally(function () {
        element.toggleClass('rateable', rateable);
      });
    }*/

      function getWidth(rating) {
        if (rating == undefined) rating = scope.personalRating || (scope.rating ?? 0);
        return 100 * rating / 5;
      }

    /*if(scope.interactive) {
      container.on('mouseenter', enter);
      container.on('mouseleave', leave);
      container.on('mousemove', move);
      container.on('click', click);
    }*/
    }

    return {
      link: link,
      scope: { itemId: '@', type: '@', interactive: '@' },
      templateUrl: '/template/rating.rating'
    };
  }]);
