// bounty
import {setTimeout} from '@acng/frontend-bounty';
import {MapStore, remove} from '@acng/frontend-bounty/collection.js';
import {size} from '@acng/frontend-bounty/std/array.js';
import {ARRAY, UNKNOWN, typeguard} from '@acng/frontend-bounty/typeguard.js';
import {cdn, get, post} from 'acng/core/service/backend.js';
import {inject} from 'acng/core/service/ng';
import {authUser} from 'acng/userPool/context/auth-user.js';

/**
 * @typedef PackageData
 * @property {string} name
 * @property {number} points
 * @property {number} coins
 */

/**
 * @typedef UserAchievementData
 * @property {string} created_at
 * @property {number} value
 * @property {AchievementData} achievement
 */

/**
 * @typedef AchievementData
 * @property {number} id
 * @property {number} value
 * @property {string} name
 */

/**
 * @param {string} name
 * @param {Element} [element]
 * @returns {Promise<AchievementData>}
 */
export const getAchievement = async (name, element) => cdn(`achievement/${name}`, element);

/**
 * @param {Element} [element]
 * @returns {Promise<UserAchievementData[]>}
 */
export const getHistory = async (element) => get('achievements/latest', element);

/**
 * @param {Element} [element]
 * @returns {Promise<PackageData[]>}
 */
export const getPackages = async (element) => cdn('achievements/packages', element);

/**
 * @param {string} name
 * @param {Element} [element]
 * @returns {Promise<PackageData | null>}
 */
export const redeemPackage = async (name, element) =>
  post('achievements/redeemCoins', {name}, element, async (error) => {
    if (error.code === 4002) {
      inject('payment').overlay('payment.buyReasonPoints');
      return null;
    } else {
      throw error;
    }
  });

/**
 * @type {(achievement: string) => Promise<boolean>}
 */
export const userHas = MapStore(async (achievement, cache) => {
  setTimeout(() => remove(cache, achievement), 5000);

  if (!authUser) {
    return false;
  }

  const achievements = await get(`achievements/user?name=${achievement}`, undefined, async (error) => {
    const error_ = new Error(error.message);
    // @ts-ignore
    error_.cause = error;
    throw new Error(error.message);
  });
  ASSERT: typeguard('', achievements, ARRAY(UNKNOWN));

  return size(achievements) > 0;
});
