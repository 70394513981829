/**
 * @todo slots
 * @module
 * @author Jacob Viertel <jv@onscreen.net>
 */

import {
  HTMLElement,
  attributeChangedCallback,
  connectedCallback,
  defineElement,
  disconnectedCallback,
} from '@acng/frontend-bounty/dom/custom.js';
import {Engine, content, defineRegistryElement} from '@acng/frontend-stargazer';
import {IS, typeguard} from '@acng/frontend-bounty/typeguard.js';
import {cloneNode, noop} from '@acng/frontend-bounty';
import {rootRoute} from '@acng/frontend-voyager';
import {ObserveChildList} from '@acng/frontend-bounty/dom/observe.js';
import {inject} from 'acng/core/service/ng.js';
import {getSequence} from '../service/sequence.js';

defineRegistryElement('onsw-view', (name) => {
  const $rootScope = inject('$rootScope');
  const $compile = inject('$compile');

  defineElement(
    name,
    class extends HTMLElement {
      static observedAttributes = ['compiled'];

      disabled = false;
      #disco = noop;

      [attributeChangedCallback]() {
        DEBUG: if (this.hasAttribute('debug')) console.log(name, 'compiled attribute changed', {isConnected: this.isConnected});
        if (this.disabled) {
          this.disabled = false;
          if (this.isConnected) {
            this[connectedCallback]();
          }
        }
      }

      [connectedCallback]() {
        if (this.hasAttribute('ng-attr-compiled') && !this.hasAttribute('compiled')) {
          DEBUG: if (this.hasAttribute('debug')) console.debug(name, 'skip connected - waiting for angularjs');
          this.disabled = true;
          return;
        }
        DEBUG: if (this.hasAttribute('debug')) console.debug(name, 'connected');
        /**
         * @type {Record<string, ?[import("@acng/frontend-stargazer").Engine, ChildNode[], ?angular.IScope]>}
         */
        const nodeLists = {};

        /**
         * @param {NodeList} nodes
         */
        const addTemplates = (nodes) => {
          for (const template of nodes) {
            ASSERT: typeguard('', template, IS(HTMLTemplateElement));
            new Engine(cloneNode(template)).render().then(async (parser) => {
              const fragment = parser.toFragment(rootRoute.globals);
              const childNodes = [...fragment.childNodes];
              const scope = $rootScope.$new(); //parser.classList.contains('req-ng') ? $rootScope.$new() : null;
              DEBUG: if (this.hasAttribute('debug')) console.info(name, 'add', template.id, {parser, childNodes, scope});
              this.classList.add(...parser.classList);
              // TODO: use comment nodes as anchor this.insertBefore(?commentNode)
              //await parser.finish;
              this.append(fragment);
              parser.connect();
              if (scope) {
                // @ts-expect-error dunno why
                $compile(childNodes)(scope);
                scope.$digest();
              }
              nodeLists[template.id] = [parser, childNodes, scope];
            });
          }
        };

        /**
         * @param {NodeList} nodes
         */
        const removeTemplates = (nodes) => {
          for (const template of nodes) {
            ASSERT: typeguard('', template, IS(HTMLTemplateElement));
            const nodeList = nodeLists[template.id];
            if (nodeList) {
              const [parser, childNodes, scope] = nodeList;
              DEBUG: if (this.hasAttribute('debug')) console.info(name, 'remove', template.id, {parser, childNodes, scope});
              for (const node of childNodes) {
                node.remove();
              }
              parser.disconnect();
              scope?.$destroy();
              this.classList.remove(...parser.classList);
              nodeLists[template.id] = null;
            }
          }

          for (const key in nodeLists) {
            const asd = nodeLists[key];
            if (asd) {
              this.classList.add(...asd[0].classList);
            }
          }
        };

        const fragment = content(getSequence(this.getAttribute('name')));
        const observe = ObserveChildList(addTemplates, removeTemplates);

        observe(fragment);

        this.#disco = () => {
          observe(null);
          removeTemplates(fragment.childNodes);
        };

        addTemplates(fragment.childNodes);
      }

      [disconnectedCallback]() {
        DEBUG: if (this.hasAttribute('debug')) console.info(name, 'disconnect', this.isConnected);
        this.#disco();
      }
    }
  );
});
