/**
 * @module
 * @author Jacob Viertel <jv@onscreen.net>
 */

// bounty/std
import {isDefined, noop} from '@acng/frontend-bounty/std/value.js';
import {createMap, get, remove, set} from '@acng/frontend-bounty/collection.js';

// bounty/dom
import {
  HTMLElement,
  connectedCallback,
  defineElement,
  disconnectedCallback,
} from '@acng/frontend-bounty/dom/custom.js';
import {ObserveAttributes} from '@acng/frontend-bounty/dom/observe.js';
import {BUTTON} from '@acng/frontend-bounty/dom/element.js';
import {TITLE, get as getAttribute, CLASS} from '@acng/frontend-bounty/dom/attribute.js';
import {onClickElement} from '@acng/frontend-bounty/dom/event.js';
import {debug} from '@acng/frontend-bounty/dom/debug.js';

// bounty/mixed
import {IS, typeguard} from '@acng/frontend-bounty/typeguard.js';
import {createElement, hasClass, removeNode} from '@acng/frontend-bounty';

// stargazer
import {Engine, defineRegistryElement} from '@acng/frontend-stargazer';

// internal
import {allScenes, fromAttribute, observe} from '../service/sequence.js';
import {enter} from '../service/animate-class.js';
import {STYLE_ACTIVE, STYLE_OPEN, swapClass} from '../service/style.js';

/**
 * Use transcluded template
 *
 * @group DOM Element
 * @since 3.87.0
 */
export const NOVA_PAGINATION = 'nova-pagination';

defineRegistryElement(NOVA_PAGINATION, (name) => {
  const transclude = Engine.Transclude(name);

  defineElement(
    name,
    class extends HTMLElement {
      #disco = noop;

      [connectedCallback]() {
        const sequence = fromAttribute(this);
        const engine = transclude(this);
        engine.toElement(this);

        /**
         * @type {Map<HTMLTemplateElement, HTMLElement>}
         */
        const buttons = createMap();
        const buttonTemplate = engine.nodes?.button;
        ASSERT: typeguard('#button', buttonTemplate, IS(HTMLTemplateElement));
        const buttonEngine = new Engine(buttonTemplate);
        const buttonParent = buttonTemplate.parentElement;
        ASSERT: typeguard('button template parent', buttonParent, IS(HTMLElement));

        const unobserveSequence = observe(
          sequence,
          (added, insertBefore) => {
            for (const scene of added) {
              add(scene, insertBefore);
            }
          },
          (removed) => {
            for (const scene of removed) {
              const button = get(buttons, scene);

              if (button) {
                removeNode(button);
                remove(buttons, scene);
              }
            }
          }
        );

        const observeScene = ObserveAttributes([TITLE, CLASS], (scene) => {
          const button = get(buttons, scene);

          if (button) {
            DEBUG: if (debug(this)) console.info('scene attribute changed', {pagination: this, scene});
            swapClass(button, STYLE_ACTIVE, hasClass(scene, STYLE_OPEN));
            button[TITLE] = getAttribute(scene, TITLE) ?? ''; // TODO default title?
          }
        });

        /**
         * @param {HTMLTemplateElement} scene
         * @param {?HTMLTemplateElement} [nextScene]
         */
        const add = (scene, nextScene) => {
          const nextButton = (nextScene && get(buttons, nextScene)) ?? null;
          const newButton = createElement(BUTTON);
          newButton[TITLE] = scene[TITLE];

          buttonEngine.toElement(newButton, {title: scene.title}, () => {
            onClickElement(newButton, () => swapClass(scene, STYLE_OPEN, true));
            buttonParent.insertBefore(newButton, nextButton);
            set(buttons, scene, newButton);

            if (isDefined(nextScene)) {
              enter(newButton);
            }
          });

          observeScene(scene);
        };

        allScenes(sequence, add);

        this.#disco = () => {
          unobserveSequence();
          observeScene(null);
          engine.disconnect();
        };
      }

      [disconnectedCallback]() {
        this.#disco();
      }
    }
  );
});
