/**
 * @module Set ID Attribute
 * @since 3.77.0
 * @author Jacob Viertel <jv@onscreen.net>
 *
 * Set the id attribute of an element to be persistent.
 *
 * @example
 * ```html
 * <div :id="persistent-id"> ... </div>
 * ```
 */

import {removeAttribute, setId} from '@acng/frontend-bounty';
import {defineRenderAttribute} from '@acng/frontend-stargazer';

const MODULE = 'layout/config/controller/id';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

defineRenderAttribute(':id', (element, id) => {
  DEBUG: if (VERBOSE || element.hasAttribute('debug')) {
    console.info(MODULE, {element, id});
  }

  removeAttribute(element, ':id');
  setId(element, id);
});
