/**
 * @module Change Friendship
 * @since 3.78.0
 * @author Jacob Viertel <jv@onscreen.net>
 *
 * This component shows a description of the current friendship status
 * of an amateur and buttons to change that status.
 */
import {Error, hide, setText, show, whenAll} from '@acng/frontend-bounty';
import {ctxFriendship, error, getFriendship} from '../context/friendships.js';
import {Widget, defineCustomWidget, defineRegistryElement} from '@acng/frontend-stargazer';
import {WatchGroup} from '@acng/frontend-relativity';

import {friendFeature} from '../config/feature.js';
import {ctxAmateur} from 'acng/amateurPool/context/amateur.js';
import {translateFeature} from 'acng/locale/config/translate.js';
import {coreFeature} from 'acng/core/config/feature.js';
import {put} from 'acng/core/service/backend.js';
import {IS, OBJECT, typeguard} from '@acng/frontend-bounty/typeguard.js';
import {
  POSSIBLE_STATES,
  STATE_CONFIRMED,
  STATE_DENIED,
  STATE_PENDING,
  STATE_REQUESTED,
  STATE_RESET,
} from '../service/friendship.js';

const MODULE = 'friend/widget/operations';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

defineRegistryElement('onsw-friend-operations', (name) => {
  const watch = WatchGroup(name, ctxAmateur, ctxFriendship);

  defineCustomWidget(
    friendFeature,
    'onsw-friend-operations',
    /**
     * @extends {Widget<{
     *   text: Text;
     *   yesLabel: Text;
     *   noLabel: Text;
     *   yesButton: HTMLButtonElement;
     *   noButton: HTMLButtonElement;
     * }>}
     */
    class ChangeFriendship extends Widget {
      /**
       * @type {Record<string, ?import('@acng/frontend-stargazer').Engine>}
       */
      static r;

      /**
       * Preload a bunch of translations
       */
      static async setup() {
        /**
         * @type {string[]}
         */
        const keys = [];
        for (const s of POSSIBLE_STATES) {
          keys.push(`explain_${s}`);
        }
        for (const [k, v] of Object.entries(opMap.yes)) {
          keys.push(`${k}_${v}`);
        }
        for (const [k, v] of Object.entries(opMap.no)) {
          keys.push(`${k}_${v}`);
        }

        this.r = Object.fromEntries(
          (await whenAll(keys.map((k) => translateFeature.render('friend', k)))).map((rendering) => [
            rendering.name,
            rendering,
          ])
        );
      }

      yes() {
        this.#click(opMap.yes);
      }

      no() {
        this.#click(opMap.no);
      }

      /**
       * @param {typeof opMap["yes"] | typeof opMap["no"]} transition
       */
      async #click(transition) {
        ASSERT: typeguard(MODULE, this.nodes, NODES);

        const {yesButton, noButton, text} = this.nodes;
        const amateur = ctxAmateur.value(this);

        if (!amateur) {
          throw Error();
        }

        hide(yesButton);
        hide(noButton);
        setText(text, null);

        const currentFriendshipState = getFriendship(amateur.id);
        const nextFriendshipState = transition[currentFriendshipState];

        let confirmed = true;
        if (currentFriendshipState == 'confirmed' && nextFriendshipState == 'reset') {
          const question = await friendFeature.translate('confirmEnd', {nickname: amateur?.getNickname()});
          confirmed = await coreFeature.popup(
            this,
            'confirm',
            /**
             * @param {(value?: boolean) => void} close
             */
            (close) => ({question, yes: () => close(true), no: () => close(false)})
          );
        }
        if (confirmed) {
          await put(`friends/${amateur.id}`, {status: nextFriendshipState}, this);
        } else {
          // Restore buttons
          if (opMap.yes[currentFriendshipState]) {
            show(yesButton);
          }
          if (opMap.no[currentFriendshipState]) {
            show(noButton);
          }
        }
      }

      link() {
        watch(this, (element, [amateur]) => {
          ASSERT: typeguard(MODULE, element.nodes, NODES);

          if (!amateur) {
            return;
          }

          const s = getFriendship(amateur.id);
          const {yesButton, noButton, yesLabel, noLabel, text} = element.nodes;

          if (error) {
            setText(text, error.message);
            hide(yesButton);
            hide(noButton);
            return;
          }

          setText(
            text,
            ChangeFriendship.r[`explain_${s}`]?.toText({nickname: amateur.getNickname()}) ?? null
          );
          if (s in opMap.yes) {
            setText(yesLabel, ChangeFriendship.r[`${s}_${opMap.yes[s]}`]?.toText() ?? null);
            show(yesButton);
          } else {
            hide(yesButton);
          }
          if (s in opMap.no) {
            setText(noLabel, ChangeFriendship.r[`${s}_${opMap.no[s]}`]?.toText() ?? null);
            show(noButton);
          } else {
            hide(noButton);
          }
        });
      }
    }
  );

  const ACTION_ACCEPT = 'accept';
  const ACTION_REQUEST = 'request';
  const ACTION_REJECT = 'reject';
  const ACTION_RESET = 'reset';

  /**
   * @type {Record<"yes" | "no", {[key in import('../context/friendships.js').FriendshipState]?: string}>}
   */
  const opMap = {
    yes: {
      [STATE_PENDING]: ACTION_ACCEPT,
      [STATE_RESET]: ACTION_REQUEST,
    },
    no: {
      [STATE_CONFIRMED]: ACTION_RESET,
      [STATE_PENDING]: ACTION_REJECT,
      [STATE_DENIED]: ACTION_RESET,
      [STATE_REQUESTED]: ACTION_RESET,
    },
  };
});

const NODES = /* @__PURE__ */ (() =>
  OBJECT({
    yesButton: IS(HTMLButtonElement),
    noButton: IS(HTMLButtonElement),
    text: IS(Text),
    yesLabel: IS(Text),
    noLabel: IS(Text),
  }))();
