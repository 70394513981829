import angular from 'angular';
import {hasFeature} from 'acng/core/service/env';
import {runTracking} from './config/routes';
import {createFrontendFeature} from 'acng/core/model/feature.js';

const name = 'hotornot';

const feature = createFrontendFeature('hotornot');
feature.hidden = !hasFeature(name);

angular
  .module(name, ['pascalprecht.translate', 'core', 'amateurPool', 'userPool'])
  .config([
    '$translatePartialLoaderProvider',
    /**
     * @param {angular.translate.ITranslatePartialLoaderProvider} $translatePartialLoaderProvider
     */
    ($translatePartialLoaderProvider) => {
      $translatePartialLoaderProvider.addPart(name);
    },
  ])
  .run(runTracking);
