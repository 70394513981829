/**
 * @todo
 * 1. Refactor into `<nova-list>`
 */

import {append, createDiv, createElement, hasClass, hide, requestAnimationFrame, show} from '@acng/frontend-bounty';
import {createPerceptiveHTML, createStream} from '@acng/frontend-discovery';
import {VERBOSE} from '../service/debug.js';

export default 'onswTiles';
export const tilesComponent = {
  bindings: {
    list: '<?',
    limit: '<?',
    /* tileFactory: '@' REQUIRED ATTRIBUTE */
  },
  controller: ['$element', '$attrs', '$injector', '$scope', Controller],
};

type ChangingArray = Array<unknown> & {changed: number};

function Controller(
  this: angular.IController & {
    list?: ChangingArray;
  },
  $element: JQLite,
  $attrs: angular.IAttributes,
  $injector: angular.auto.IInjectorService,
  $scope: angular.IScope
) {
  let active = false;
  let lastVersion: number | undefined;
  let lastList: any[];
  const element = $element[0];
  // @ts-ignore: does not compute
  const _Tile: core.TileConstructor = $injector.get($attrs.tileFactory);
  const scopes = new WeakMap<object, angular.IScope>();
  /**
   * Take first word of the list definition attribute as drive id.
   */
  const id = $attrs.list.split(/\W/)[0];
  const stream = createStream<unknown>([], async () => []);
  const perceptive = createPerceptiveHTML(
    element,
    stream,
    async (data, loader) => {
      const template = createElement('template');
      const child = createDiv();
      hide(child);
      append(template.content, child);
      const tile = new _Tile(data, $scope.$new(), child);
      scopes.set(loader, tile.scope);
      DEBUG: VERBOSE(element) && console.debug(`onsw-tiles ${id} construct`, {tile});
      await tile.get();
      requestAnimationFrame(() => show(child));
      return template;
    },
    (loader) => void scopes.get(loader)?.$destroy()
  );

  if (hasClass(element, 'history')) {
    perceptive.enableHistory(id);
  }

  DEBUG: if (VERBOSE(element)) {
    console.warn('VERBOSE DEBUG MODE', {id});
    perceptive.enableDebug(id);
  }

  this.$onChanges = (changed: {
    list?: angular.IChangesObject<ChangingArray>; //
  }) => {
    DEBUG: VERBOSE(element) && console.debug(`onsw-tiles:${id} $onChanges`, {changed});
    if (changed.list) {
      if (this.list instanceof Array) {
        stream.streamReset(this.list.slice());
        perceptive.connect();
        lastVersion = this.list.changed;
        lastList = this.list;
        active = true;
      } else if (changed.list.previousValue instanceof Array) {
        perceptive.disconnect();
        active = false;
      }
    }
  };

  this.$doCheck = () => {
    if (!active || !this.list) {
      return;
    }
    if (this.list === lastList && lastVersion !== this.list?.changed) {
      lastVersion = this.list?.changed;
      DEBUG: VERBOSE(element) && console.debug(`onsw-tiles:${id} list change detected - morph list`);
      stream.streamReset(this.list.slice());
      perceptive.connect();
    }
  };

  this.$onDestroy = () => perceptive.disconnect();
}
