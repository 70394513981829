// bounty, relativity, nova
import {Context, provide} from '@acng/frontend-relativity';
import {documentElement} from '@acng/frontend-bounty';
import {ctxTiming} from 'acng/layout/context/timing.js';

/**
 * @type {import('@acng/frontend-relativity').ObservableType<?import('../model/bonus').Bonus>}
 */
export const ctxBonus = Context();

/**
 * @param {Element} element
 * @param {?import("../model/bonus").Bonus} bonus
 */
export const provideBonus = (element, bonus) => {
  provide(ctxBonus, bonus, element);
  //provide(ctxTiming, bonus?.timing ?? null, element);
};

provide(ctxBonus, null, documentElement);
