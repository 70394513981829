/**
 * @module Warpdrive
 * @author Jacob Viertel <jv@onscreen.net>
 */

import {HTMLElement, connectedCallback, disconnectedCallback} from '@acng/frontend-bounty/dom/custom.js';
import {createPerceptiveHTML, createStream} from '@acng/frontend-discovery';
import {Error, append, createTemplate} from '@acng/frontend-bounty';
import {Engine} from '@acng/frontend-stargazer';
import {getElementById} from '@acng/frontend-bounty/dom/query.js';
import {IS, typeguard} from '@acng/frontend-bounty/typeguard.js';

const MODULE = 'discovery/warpdrive';

/**
 * @abstract
 * @template T
 */
export class Warpdrive extends HTMLElement {
  #transcluded = createTemplate();
  #stream = createStream([], (offset) => this.more(offset, this.#renderEmpty));
  #warpdrive = createPerceptiveHTML(this, this.#stream, (data, loader) =>
    this.render(data, this.#renderItem, loader)
  );

  /**
   * @type {?Engine}
   */
  #renderItem = null;

  /**
   * @type {?Engine}
   */
  #renderEmpty = null;

  constructor() {
    super();
    DEBUG: if (this.hasAttribute('debug')) {
      this.#warpdrive.enableDebug(this.localName);
    }
  }

  /**
   * @abstract
   * @param {number} offset
   * @param {?Engine} render
   * @returns {Promise<T[]>}
   */
  async more(offset, render) {
    throw Error();
  }

  /**
   * @abstract
   * @param {T} item
   * @param {import("@acng/frontend-discovery").Loader} loader
   * @param {?Engine} render
   * @returns {Promise<void | HTMLTemplateElement>}
   */
  async render(item, render, loader) {
    throw Error();
  }

  link() {
    this.warp();
  }

  warp() {
    this.#warpdrive.disconnect();
    this.#stream.streamReset([]);
    this.replaceChildren();
    this.#warpdrive.connect();
  }

  [connectedCallback]() {
    const fragment = this.#transcluded.content;
    append(fragment, ...this.childNodes);

    const item = getElementById(fragment, 'item');
    const empty = getElementById(fragment, 'empty');

    ASSERT: {
      typeguard(MODULE, item, IS(HTMLTemplateElement));
      typeguard(MODULE, empty, IS(HTMLTemplateElement));
    }

    this.#renderItem = new Engine(item);
    this.#renderEmpty = new Engine(empty);

    this.link();
  }

  [disconnectedCallback]() {
    this.#warpdrive.disconnect();
    this.replaceChildren(...this.#transcluded.content.childNodes);
  }
}
