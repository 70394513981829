import {defineRegistryAttribute, defineRenderAttribute} from '@acng/frontend-stargazer';
import {Watch} from '@acng/frontend-relativity';
import {provideBonus} from 'acng/payment/context/bonus.js';
import {ctxBonusList, getBonus} from 'acng/payment/context/bonus-list.js';
import {isDefined} from '@acng/frontend-bounty/std/value.js';

/**
 * Provide a bonus context to the DOM subtree.
 *
 * The bonus name must be specified in the attribute value and the bonus 
 * will only appear in the context as long as it is available to the user.
 *
 * @example
 * ```html
 * <div payment-bonus="50percent"></div>
 * ```
 *
 * @see [Bonus Model](../model/bonus.js)
 * @see [Bonus Context](../context/bonus.js)
 * @group DOM Attribute
 */
export const PAYMENT_BONUS = 'payment-bonus';

defineRegistryAttribute(PAYMENT_BONUS, (name) => {
  const watch = Watch(`[${name}]`, ctxBonusList);

  defineRenderAttribute(name, (element, bonusName) => {
    provideBonus(element, getBonus(bonusName));

    return () =>
      watch(element, (element, bonusList, previous) => {
        if (isDefined(previous)) {
          provideBonus(element, getBonus(bonusName));
        }
      });
  });
});
