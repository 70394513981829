/**
 * Responsive Styles
 *
 * `[data-class-media]` allows to apply CSS classes depending on **Media Queries**.
 *
 * @example
 * ```html
 * <nav data-class-media="mobile: (max-width: 767px); desktop: (min-width: 768px)">
 *   ...
 * </nav>
 *
 * <h1 data-class-media="very-special-range: (min-width: 500px) and (max-width: 600px)">
 *  I am responsive headline
 * </h1>
 * ```
 *
 * @see [Media Queries](https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_media_queries/Using_media_queries)
 *
 * @module [data-class-media]
 * @author Jacob Viertel <jv@onscreen.net>
 * @author Markus Kirscht <markus.kirscht@iventuregroup.com>
 * @since 3.87.0
 */

import {defineRegistryAttribute, defineRenderAttribute} from '@acng/frontend-stargazer';
import {matchAllMap} from '@acng/frontend-bounty/std/string.js';
import {ConnectMatchMedia} from '@acng/frontend-bounty/cssom/media.js';
import {swapClass} from '../service/style.js';
import {ConnectEach} from '../service/callbacks.js';

defineRegistryAttribute('data-class-media', (name) => {

  const syntax = /([\w-]+):([^;]+);?/g;

  defineRenderAttribute(name, (element, value) => {
    const connectedCallbacks = matchAllMap(value, syntax, ([, className, query]) =>
      ConnectMatchMedia(query, (matches) => {
        DEBUG: if (element.hasAttribute('debug')) console.info({element, className, matches, query});
        swapClass(element, className, matches);
      })
    );

    ASSERT: if (!connectedCallbacks.length) console.warn(name, 'without valid rules', {element});

    return ConnectEach(element, connectedCallbacks);
  });
});
