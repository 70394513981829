/**
 * @group Style
 */
export const STYLE_ENTER = 'layout-enter';

/**
 * @group Style
 */
export const STYLE_LEAVE = 'layout-leave';

/**
 * @group Style
 * TODO onsw-view, onsw-slideshow
 */
export const STYLE_INIT = 'layout-init';
