// bounty
import {defineElement, HTMLElement} from '@acng/frontend-bounty/dom/custom.js';
import {connectedCallback, disconnectedCallback} from '@acng/frontend-bounty/dom/custom.js';
import {whenAll} from '@acng/frontend-bounty/std/control.js';
import {push, shift, size} from '@acng/frontend-bounty/std/array.js';
import {loadImage, removeNode, setStyle} from '@acng/frontend-bounty';
import {TITLE, get} from '@acng/frontend-bounty/dom/attribute.js';
import {onClickElement} from '@acng/frontend-bounty/dom/event.js';
import {closest} from '@acng/frontend-bounty/dom/query.js';
import {ENUM, typeguard} from '@acng/frontend-bounty/typeguard.js';
import {now} from '@acng/frontend-bounty/timing/now.js';

// stargazer, relativity & nova
import {defineRegistryElement, defineCompileElement, Engine} from '@acng/frontend-stargazer';
import {localProvide} from '@acng/frontend-relativity';
import {TYPE} from 'acng/layout/attribute/common.js';

// enterprise
import {inject} from 'acng/core/service/ng.js';
import {ctxAmateur} from '../context/amateur.js';
import {fskTrans} from 'acng/userPool/0fsk.js';
import {t} from 'acng/locale/config/translate.js';
import {AOTM_TYPE, LOTM_TYPE, OTM_TYPE_LIST, getList, sort} from '../service/otm.js';
import {isOnline} from '../service/online.js';
import {media} from 'acng/core/service/env.js';
import {MESSENGER_SEND} from 'acng/messenger/widget/send.js';

/**
 * @group DOM ELement
 */
export const AMATEUR_OTM = 'amateur-otm';

defineRegistryElement(AMATEUR_OTM, (name) => {
  /**
   * @type {Record<OTM_TYPE_LIST[number], import('../types.js').Otm[]>}
   */
  const list = {
    [AOTM_TYPE]: [],
    [LOTM_TYPE]: [],
  };

  /**
   * @type {Record<OTM_TYPE_LIST[number], {
   *   time?: number,
   *   otm?: import('../types.js').Otm,
   *   amateur?: import('acng/amateurPool/factory/Amateur').Amateur,
   *   title?: string,
   * }>}
   */
  const scopeFor = {
    [AOTM_TYPE]: {},
    [LOTM_TYPE]: {},
  };

  const template = Engine.Transclude(name);

  /**
   * @param {Element} element
   */
  const compile = async (element) => {
    const type = get(element, TYPE);
    ASSERT: typeguard(`${name} type attribute`, type, ENUM(OTM_TYPE_LIST));

    if (scopeFor[type]?.time ?? 0 > now() - 5 * 60000) {
      return;
    }

    if (!size(list[type])) {
      list[type] = await getList(element, type);
      switch (type) {
        case AOTM_TYPE:
          sort(list[type], isOnline);
          break;
        case LOTM_TYPE:
          sort(list[type], inject('Livecam').isOnline);
          break;
      }
    }

    const otm = shift(list[type]);

    if (!otm) {
      DEBUG: console.info('no aotm, remove element', {formerParent: element.parentElement, element});
      removeNode(element);

      return;
    }

    scopeFor[type].otm = otm;

    const titleResource = type == AOTM_TYPE ? 'amateurPool.amateurOfTheMoment' : 'livecam.livecamOfTheMoment';
    await whenAll([
      // @ts-expect-error Transclude is not generic
      t(titleResource).then((res) => (element[TITLE] = res)),
      inject('Amateur')
        .get(otm.amateurId)
        .then((res) => (scopeFor[type].amateur = res)),
      loadImage(`${media.content.pictures}${otm.image_xl}`),
    ]);
    scopeFor[type].time = now();
  };

  defineCompileElement(name, (element, promises) => push(promises, compile(element)));

  defineElement(
    name,
    class extends HTMLElement {
      #engine = Engine.root;

      constructor() {
        super();
        const type = get(this, TYPE);
        ASSERT: typeguard(`${name} type attribute`, type, ENUM(OTM_TYPE_LIST));

        onClickElement(this, (element) => {
          if (!closest(this, element, MESSENGER_SEND)) {
            scopeFor[type].amateur?.goto();
          }
        });
      }

      [connectedCallback]() {
        const type = get(this, TYPE);
        ASSERT: typeguard(`${name} type attribute`, type, ENUM(OTM_TYPE_LIST));

        const {otm, amateur} = scopeFor[type];

        if (!otm || !amateur) {
          return;
        }

        localProvide(this, ctxAmateur, amateur);

        setStyle(this, '--otm-color', otm.color);
        setStyle(this, '--otm-color-from', `${otm.color}00`);
        setStyle(this, '--otm-color-to', `${otm.color}FF`);
        setStyle(this, '--otm-image-src', `url(${media.content.pictures}${otm.image_xl})`);

        this.#engine = template(this);
        this.#engine.toElement(this, {
          amateurId: amateur.id,
          nickname: amateur.getNickname(),
          age: amateur.age,
          text: fskTrans(otm.text),
        });
      }

      [disconnectedCallback]() {
        this.#engine.disconnect();
      }
    }
  );
});
