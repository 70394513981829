import {createContext, createGlobalContext} from '@acng/frontend-relativity';
import {CTX_PROVIDE} from '@acng/frontend-relativity/minify';

import {API_cdn} from 'acng/core/service/env';
import {inject} from 'acng/core/service/ng';

/**
 * Provide methods to
 * - provide a commentable
 * - obtain a commentable
 * - create a comment on a commentable
 * - list comments of a commentable
 * - count comments of a commentable
 * - observe comment creation
 *
 * @module acng/comment/model/commentable
 */

/**
 * A `Commentable` can be used to create a comment or to list comments.
 *
 * @typedef Commentable
 * @property {"movie" | "pictures"} type
 * @property {number} id
 * @property {() => Promise<"comment.buyPictureSet" | "comment.buyMovie" | "comment.freeUser" | "comment.notCommentable" | false>} [blockWithReason]
 */

/**
 * The `CommentData` should be returned by the ACNG backend.
 *
 * @typedef CommentData
 * @property {string} nickname
 * @property {string} created_at
 * @property {string} text
 */

/**
 * The `CommentData` will be processed to become a `Comment`.
 *
 * @typedef {Omit<CommentData, 'created_at'> & {created_at: Date}} Comment
 */

/**
 * @typedef CommentsData
 * @property {number} total
 * @property {number} offset
 * @property {number} limit
 * @property {Array<CommentData>} chunk
 */

/**
 * @type {import('@acng/frontend-relativity').LegacyContext<Commentable> & import('@acng/frontend-relativity').ObservableType<Commentable>}
 */
export const ctxCommentable = createContext();

/**
 * @type {import('@acng/frontend-relativity').LegacyContext<null | {
 *   commentable: Commentable;
 *   comment: Comment;
 * }> & import('@acng/frontend-relativity').ObservableValue<null | {commentable: Commentable, comment: Comment}>}
 */
export const ctxCreatedComment = createGlobalContext(null);

/**
 * Write a comment.
 * @param {Commentable} commentable
 * @param {string} text
 */
export const createComment = async ({type, id}, text) => {
  const res = await inject('http')().post(
    `/api/comments/${type}/${id}`,
    {text},
    {
      headers: {
        'Content-Type': 'application/json',
      },
      noMessageBag: true,
    }
  );
  /** @type {CommentData} */
  const comment = res.data;
  ctxCreatedComment[CTX_PROVIDE](null, {
    commentable: {type, id},
    comment: {
      nickname: comment.nickname,
      created_at: new Date(comment.created_at),
      text: comment.text,
    },
  });
};

/**
 * List comments.
 * @param {Commentable} commentable
 * @param {number} limit
 * @param {number} offset
 * @returns {Promise<Comment[]>}
 */
export const listComments = async ({type, id}, limit, offset) => {
  const res = await fetch(`/api/comments/${type}/${id}?limit=${limit}&offset=${offset}`);
  /** @type {CommentsData} */
  const data = await res.json();
  return data.chunk.map(({nickname, created_at, text}) => ({
    nickname,
    created_at: new Date(created_at),
    text,
  }));
};

/**
 * @param {Commentable} commentable
 */
export const countComments = async ({type, id}) => {
  const res = await fetch(`${API_cdn}/api/comments/${type}/${id}?limit=0&offset=0`);
  /** @type {CommentsData} */
  const data = await res.json();
  return data.total;
};
