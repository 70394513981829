import {removeNode} from '@acng/frontend-bounty';
import {debug} from '@acng/frontend-bounty/dom/debug.js';
import {join} from '@acng/frontend-bounty/std/array.js';
import {matchAllMap} from '@acng/frontend-bounty/std/string.js';
import {defineCompileAttribute, defineRegistryAttribute} from '@acng/frontend-stargazer';
import {inject} from 'acng/core/service/ng';

/**
 * The element is removed from the cached template if the split group condition is not met.
 *
 * Multiple group conditions can be specified.
 *
 * ## Options
 * - "none", not assigned (including guests)
 * - "test-group", assigned to a group number larger than `0`
 * - "control-group", not assigned or assigned to the group number `0`
 * - {number}, assigned to the group number {number}
 *
 * @example
 * ```html
 * <div user-split="none@123"> not assigned to test #123 </div>
 * <div user-split="control-group@123"> control group of test #123</div>
 * <div user-split="test-group@123"> test group of test #123</div>
 * <div user-split="3@123"> group #3 of test #123 </div>
 * <div user-split="none@123; 3@123"> group #3 of test #123 OR not assigned </div>
 * ```
 *
 * @group DOM Attribute
 * @author Jacob Viertel <jv@onscreen.net>
 * @since 3.87.0
 */
export const USER_SPLIT = 'user-split';

defineRegistryAttribute(USER_SPLIT, (attributeName) => {
  const NONE = 'none';
  const CONTROL_GROUP = 'control-group';
  const TEST_GROUP = 'test-group';
  const user = inject('user');
  const syntax = new RegExp(`(\\d+|${join([NONE, CONTROL_GROUP, TEST_GROUP], '|')})@(\\d+)`, 'g');

  defineCompileAttribute(attributeName, (element, attributeValue) => {
    const options = matchAllMap(attributeValue, syntax, (option) => {
      let result;

      const splitTestId = Number(option[2]);
      const assignment = user.split_tests.find(({id}) => id == splitTestId);

      switch (option[1]) {
        case NONE:
          result = !assignment;
          break;
        case CONTROL_GROUP:
          result = !assignment || !assignment.group;
          break;
        case TEST_GROUP:
          result = assignment && assignment.group > 0;
          break;
        default:
          result = assignment?.group === Number(option[1]);
      }

      DEBUG: if (debug(element)) {
        console.debug(`[${attributeName}] check`, {element, attributeValue, option, result, assignment});
      }

      return result;
    });

    DEBUG: if (debug(element)) {
      if (options.includes(true)) {
        console.info(`[${attributeName}] confirmed`, {element, attributeValue, options, syntax});
      } else {
        console.info(`[${attributeName}] removed`, {element, attributeValue, options, syntax});
      }
    }

    if (!options.includes(true)) {
      removeNode(element);
    }
  });
});
