// bounty
import {isDefined} from '@acng/frontend-bounty/std/value.js';
import {sleep} from '@acng/frontend-bounty/sleep.js';
import {debug} from '@acng/frontend-bounty/dom/debug.js';
import {removeNode} from '@acng/frontend-bounty';
import {
  HTMLElement,
  connectedCallback,
  defineElement,
  disconnectedCallback,
} from '@acng/frontend-bounty/dom/custom.js';

// stargazer, relativity, discovery and nova
import {Engine, defineCompileElement, defineRegistryElement} from '@acng/frontend-stargazer';
import {createPerceptiveHTML, createStream} from '@acng/frontend-discovery';
import {Watch} from '@acng/frontend-relativity';
import {STYLE_FILLED, swapClass} from 'acng/layout/service/style.js';

// enterprise
import {pinFeature} from '../config/feature.js';
import {byAmateur, ctxPins} from '../service.js';
import {AmateurTile} from 'acng/amateurPool/tiles/AmateurTile.js';

const MODULE = 'pin/widget/list';
const VERBOSE = true;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

/**
 * @group DOM Element
 */
export const PIN_LIST = 'pin-list';

defineRegistryElement(PIN_LIST, (name) => {
  defineCompileElement(name, (element) => {
    if (pinFeature.isHidden) {
      removeNode(element);
    }
  });

  const template = Engine.Transclude(name);

  /**
   * @type {WeakMap<object, import('angular').IScope>}
   */
  const scopes = new WeakMap();

  const watch = Watch(name, ctxPins);

  defineElement(
    name,
    class extends HTMLElement {
      #engine = Engine.root;

      #stream = createStream([], async (offset) => {
        if (offset) {
          return [];
        }

        swapClass(this, STYLE_FILLED, !!byAmateur.size);

        return [...byAmateur];
      });

      #warpdrive = createPerceptiveHTML(
        this,
        this.#stream,
        async (data, loader) => {
          const tile = new AmateurTile(data);
          await tile.render(this.#engine, loader);
          scopes.set(loader, tile.scope);
        },
        (loader) => scopes.get(loader)?.$destroy()
      );

      [connectedCallback]() {
        DEBUG: if (debug(this, VERBOSE)) this.#warpdrive.enableDebug(MODULE);
        this.#engine = template(this);

        watch(this, (_element, pinsAvailable, previous) => {
          this.#warpdrive.disconnect();
          this.#stream.streamReset([]);
          this.replaceChildren();
          this.#warpdrive.connect();
          if (isDefined(previous)) {
            sleep(10).then(() => this.parentElement?.scrollIntoView({behavior: 'smooth', block: 'nearest'}));
          }
        });
      }

      [disconnectedCallback]() {
        this.#warpdrive.disconnect();
        this.#engine.disconnect();
      }
    }
  );
});
