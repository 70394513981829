// bounty
import {append, create} from '@acng/frontend-bounty/dom/template.js';
import {body, removeNode} from '@acng/frontend-bounty';
// stargazer & relativity
import {Watch, open} from '@acng/frontend-relativity';
import {
  defineCompileAttribute,
  defineRegistryAttribute,
  defineRenderAttribute,
  task,
} from '@acng/frontend-stargazer';
import {Engine, disconnect} from '@acng/frontend-stargazer';
// enterprise
import {ctxVipPoints} from '../service/sock.js';
import {userHas} from '../service/http.js';
import {HIDDEN, remove} from '@acng/frontend-bounty/dom/attribute.js';
import {add, createSet, has} from '@acng/frontend-bounty/collection.js';
import {IS, typeguard} from '@acng/frontend-bounty/typeguard.js';
import {authUser} from 'acng/userPool/context/auth-user.js';
import {debug} from '@acng/frontend-bounty/dom/debug.js';
import {attributeSelector} from '@acng/frontend-bounty/dom/query.js';

const DEBUG_ALL = false;

/**
 * Remove the element until the user has achieved a certain challenge.
 *
 * @example
 * ```html
 * <div with-achievement="clicked-the-stuff">
 *   <strong>YES!</strong>
 *   You clicked the balloons and the bats.
 * </div>
 * ```
 *
 * @group DOM Attribute
 */
export const WITH_ACHIEVEMENT = 'with-achievement';

defineRegistryAttribute(WITH_ACHIEVEMENT, (name) => {
  /**
   * Internal synchronous(!) cache for achieved challenges.
   *
   * @type {Set<string>}
   */
  const achievements = createSet();

  /**
   * There is no achievement event a.t.m.
   * So watch for points value.
   */
  const watch = Watch(attributeSelector(name), ctxVipPoints);

  defineCompileAttribute(name, async (element, achievement, p, engine) => {
    DEBUG: if (DEBUG_ALL) element.setAttribute('debug', '');
    if (!authUser) {
      DEBUG: if (debug(element)) console.log('remove [${name}]', {});
      removeNode(element);

      return;
    }

    const check = userHas(achievement);

    task(engine, check);

    if (await check) {
      add(achievements, achievement);
    }
  });

  defineRenderAttribute(name, (element, achievement, scope, linker) => {
    if (has(achievements, achievement)) {
      return;
    }

    const template = create();
    const dummy = element.cloneNode();
    const engine = new Engine(template);
    ASSERT: typeguard(`[${name}]`, dummy, IS(HTMLElement));
    DEBUG: if (debug(element)) console.log(`stash [${name}=${achievement}]`, {element, dummy, linker, engine});

    dummy[HIDDEN] = true;
    element.replaceWith(dummy);
    open(body, template.content);
    remove(element, name);
    append(template, element);

    watch(dummy, async () => {
      if (await userHas(achievement)) {
        DEBUG: if (debug(element)) console.log(`pop [${name}=${achievement}]`, {element, dummy, linker, engine});
        add(achievements, achievement);
        const fragment = engine.toFragment(scope);
        dummy.replaceWith(fragment);
        engine.connect();
      }
    });

    return () => () => disconnect(engine);
  });
});
