/**
 * @module acng/livecam
 */
import angular from 'angular';
import './config/routes';
import {provideTranslations} from 'acng/core/service/angular-translate.js';
import './widgets/list-sidebar.js';
import './widgets/motto.js';
import './element/index.js';
import './attribute/index.js';
import name from './config/feature.js';
import {AmateurTile} from 'acng/amateurPool/tiles/AmateurTile.js';

angular
  .module(name)
  .config(provideTranslations(name))
  .factory(
    'SmallLivecamTile',
    () =>
      class extends AmateurTile {
        hookname() {
          return 'livecamTile';
        }
      }
  );
