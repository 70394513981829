import {GlobalPopupElement, PopupElement, setNoteElementLoader} from '@acng/frontend-discovery';
import {createDiv} from '@acng/frontend-bounty';
import {Feature} from 'acng/core/model/feature';

const name = 'layout';
export default name;
export const layoutFeature = new Feature(name, false);

layoutFeature.defineElement('onsw-popup', PopupElement);
layoutFeature.defineElement('onsw-global-popup', GlobalPopupElement);

setNoteElementLoader(async (severity, message) =>
  (await layoutFeature.render('note')).toElement(
    createDiv(severity),
    {message},
    (_nodes, div) => (div.dataset.popupClose = '')
  )
);
