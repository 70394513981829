import {
  defineCompileAttribute,
  defineRegistryAttribute,
  defineRenderAttribute,
} from '@acng/frontend-stargazer';
import {fromAttribute} from '../service/sequence';
import {CLICK, on} from '@acng/frontend-bounty/dom/event.js';
import {ChildList, TEMPLATE} from '@acng/frontend-bounty/dom/element.js';
import {content, query} from '@acng/frontend-bounty/dom/template.js';
import {removeNode} from '@acng/frontend-bounty';
import {debug} from '@acng/frontend-bounty/dom/debug.js';
import {set} from '@acng/frontend-bounty/dom/attribute.js';
import {hex, rand} from '@acng/frontend-bounty/std/number.js';
import {attributeSelector} from '@acng/frontend-bounty/dom/query.js';

export const CLICK_REMOVE_SCENE = 'click-remove-scene';

defineRegistryAttribute(CLICK_REMOVE_SCENE, (attributeName) => {
  defineCompileAttribute(attributeName, (element, value) => {
    if (!value) {
      set(element, attributeName, hex(rand(2 ** 32)));
    }
  });

  defineRenderAttribute(attributeName, (element, value) => {
    DEBUG: if (debug(element)) console.debug('add close scene handler', {element});

    on(element, CLICK, () => {
      const sequence = fromAttribute(element);
      const selector = attributeSelector(attributeName, value);

      for (const scene of ChildList(content(sequence), TEMPLATE)) {
        if (query(scene, selector)) {
          DEBUG: if (debug(element)) console.debug('close scene', {element, sequence, scene});

          removeNode(scene);

          return;
        }
      }

      ASSERT: console.warn('scene not found', {element, sequence});
    });
  });
});
