import {STYLE_ACTIVE} from '../config/style.js';
import {STYLE_BUSY} from '../service/style.js';
import {STYLE_TOOLBAR} from './toolbar.js';

/**
 * ## Animated Transitions
 * - `cube3d`
 *   - `left`
 *   - `right`
 * - `remove-to`
 * - `fade`
 *
 * ## Variables
 * ```css
 * .layout-slideshow {
 *   --slideshow-border-radius: none;
 *   --slideshow-transition-duration: 1s;
 *   --slideshow-toolbar-margin: 0 0 6px 0:
 * }
 * ```
 *
 * ## Keyframes
 * many
 *
 * @example
 * ```html
 * <link rel="stylesheet" href="css/layout/slideshow.css">
 *
 * @example
 * ```html
 * <onsw-slideshow class="nova-slideshow">
 *   <template title="Slide 1"> ...Content Slide 1... </template>
 *   <template title="Slide 2"> ...Content Slide 2... </template>
 *
 *   <onsw-pagination class="nova-dots layout-toolbar">
 *     <template>${ title }</template>
 *   </onsw-pagination>
 * </onsw-slideshow>
 * ```
 *
 * @see {@link STYLE_TOOLBAR | layout-toolbar}
 * @see {@link STYLE_ACTIVE | layout-active}
 * @see {@link STYLE_BUSY | layout-busy}
 *
 * @group Style
 */
export const SLIDESHOW = 'nova-slideshow';

/**
 * @group CSS Class
 */
export const SLIDESHOW_SLIDE = 'slide';

/**
 * @group CSS Custom Property
 */
export const __SLIDESHOW_BORDER_RADIUS = '--slideshow-border-radius';

/**
 * @group CSS Custom Property
 */
export const __SLIDESHOW_TRANSITION_DURATION = '--slideshow-transition-duration';

/**
 * @group CSS Custom Property
 */
export const __SLIDESHOW_WIDTH = '--slideshow-width';

// TODO keyframes
