import {defineRegistryAttribute, defineRenderAttribute} from '@acng/frontend-stargazer';
import {inject} from 'acng/core/service/ng.js';

/**
 * It may get broken if connected twice.
 *
 * @group DOM Attribute
 */
export const NOVA_NG_COMPILE = 'nova-ng-compile';

defineRegistryAttribute(NOVA_NG_COMPILE, (attributeName) => {
  let $rootScope = inject('$rootScope');
  let $compile = inject('$compile');

  defineRenderAttribute(attributeName, () => (element) => {
    const $scope = $rootScope.$new();

    $compile(element)($scope);
    $scope.$digest();

    return () => $scope.$destroy();
  });
});
