import {HTMLElement, floor, removeChildNodes, sizeOfArray, toggleClass, whenAll} from '@acng/frontend-bounty';
import {livecamFeature} from '../config/feature.js';
import {createPerceptiveHTML, createStream} from '@acng/frontend-discovery';
import {inject} from 'acng/core/service/ng.js';
import {tileFeature} from 'acng/core/config/feature.js';
import {ctxAmateur} from 'acng/amateurPool/context/amateur.js';
import {AmateurTile} from 'acng/amateurPool/tiles/AmateurTile.js';
import {shuffle} from 'acng/core/factory/helper.js';

const WARP = Symbol();
/**
 * @type {WeakMap<object, import('angular').IScope>}
 */
const scopes = new WeakMap();

livecamFeature.defineElementLoader('onsw-livecams-sidebar', async (localName) => {
  const [smallTemplate, largeTemplate] = await whenAll([
    tileFeature.render('livecamTile'),
    tileFeature.render('tileAmateur'),
  ]);
  const Livecam = inject('Livecam');

  ctxAmateur.subscribeTagName(localName);

  return class extends HTMLElement {
    connectedCallback() {
      ctxAmateur.observe(this, (sedcard) => {
        this[WARP]?.disconnect();
        removeChildNodes(this);
        toggleClass(this, 'not-sedcard', !sedcard);

        this[WARP] = createPerceptiveHTML(
          this,
          createStream([], async () => {
            const list = await Livecam.list();

            // (Staging) fallback
            if (list.length < 12) {
              return inject('AmateurFilter').get('livecam_shuffle').list(20000);
            }

            const result = list.filter((id) => !/^VX/.test(id) && id != sedcard?.id);
            shuffle(result, floor((sizeOfArray(result) * 2) / 3));
            return result;
          }),
          (amateurId, loader) => {
            const tile = new AmateurTile(amateurId);
            scopes.set(loader, tile.scope);
            return tile.render(sedcard ? largeTemplate : smallTemplate, loader);
          },
          (loader) => scopes.get(loader)?.$destroy()
        );

        this[WARP].connect();
      });
    }

    disconnectedCallback() {
      this[WARP]?.disconnect();
    }
  };
});
