/**
 * Just remove if user has the achievement. It will never appear again.
 * @module
 */

// bounty
import {debug} from '@acng/frontend-bounty/dom/debug.js';
import {removeNode} from '@acng/frontend-bounty';
// stargazer & relativity
import {Watch} from '@acng/frontend-relativity';
import {
  defineCompileAttribute,
  defineRegistryAttribute,
  defineRenderAttribute,
  task,
} from '@acng/frontend-stargazer';
// enterprise
import {ctxVipPoints} from '../service/sock.js';
import {userHas} from '../service/http.js';
import {attributeSelector} from '@acng/frontend-bounty/dom/query.js';

const DEBUG_ALL = false;

/**
 * Remove the element if the user has achieved a specified challenge.
 *
 * @example
 * ```html
 * <span without-achievement="clicked-the-stuff">
 *   <a href="...">Click here</a>
 *   to click the stuff and achieve "clicked-the-stuff".
 * </a>
 * ```
 *
 * ### Formal Syntax
 * ```
 * without-achievement = <name>
 * <name> := $(echo select name from acng.achievements | mysql -N)
 * ```
 *
 * @group DOM Attribute
 */
export const WITHOUT_ACHIEVEMENT = 'without-achievement';

defineRegistryAttribute(WITHOUT_ACHIEVEMENT, (name) => {
  /**
   * There is no achievement event a.t.m.
   * So watch for points value.
   */
  const watch = Watch(attributeSelector(name), ctxVipPoints);

  defineCompileAttribute(name, async (element, achievement, p, compiler) => {
    DEBUG: if (DEBUG_ALL) element.setAttribute('debug', '');
    const check = userHas(achievement);
    DEBUG: if (debug(element)) console.log(`check [${name}=${achievement}]`, {element, check, compiler});

    task(compiler, check);

    if (await check) {
      DEBUG: if (debug(element)) console.log(`remove [${name}=${achievement}]`, {element});
      removeNode(element);
    }
  });

  defineRenderAttribute(name, (element, achievement) => {
    watch(element, async (element) => {
      if (await userHas(achievement)) {
        // ...asap
        removeNode(element);
      }
    });
  });
});
